import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';
import { Clients } from 'src/app/models/Client';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { TransactionStatus } from 'src/app/shared/enums/transactionStatus';
import { ApiService } from 'src/app/services/api/api.service';
import { forkJoin } from 'rxjs';
import { BasePage } from '../base.page';
import { Account } from 'src/app/models/Account';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent extends BasePage implements OnInit {

  // Services
  apiService: ApiService;

  // Variables
  itemsPerPage: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;
  reverse: boolean = false;
  config: PaginationInstance = {
    itemsPerPage: this.itemsPerPage,
    currentPage: this.currentPage,
    totalItems: this.totalItems
  };
  kycStatus: string[] = ["F", "P", "S"];
  accountArr: Account[] = [];
  filteredAccountArr: Account[] = [];
  search: string = "";
  kycOption: string = "All";
  showToast: boolean = false;

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    injector: Injector
  ) {
    super();
    this.apiService = injector.get(ApiService);
  }

  override ngOnInit() {
    this.kycStatus = ["F", "P", "S"];
    this.getAccountDetails();
    this.route.queryParams.subscribe(params => {
      this.showToast = params['showToast'] === 'true';
    });
  }

  getAccountDetails() {
    this.accountArr = [];
    this.filteredAccountArr = [];
    this.loading = true;
    const getCountries = this.apiService.getCountries();
    const getAccounts = this.apiService.getUserListWithParams(this.search, this.kycStatus, this.currentPage, this.itemsPerPage);

    forkJoin([getCountries, getAccounts]).subscribe({
      next: (results: any) => {
        const countryArr = results[0].data.countryList;
        this.totalItems = results[1].total;
        this.accountArr = results[1].data;
        for (const account of this.accountArr) {
          account.country = countryArr.filter((country: any) => {
            return account.countryCode === country.code ? country : '';
          })[0].name;
          account.kycStatus = TransactionStatus[account.kycStatus as keyof typeof TransactionStatus];
        }
        this.filteredAccountArr = JSON.parse(JSON.stringify(this.accountArr));
        this.loading = false;
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    });
  }

  pageChanged(event: any) {
    this.currentPage = event;
    this.getAccountDetails();
  }

  onKycOptionChange(event: any) {
    this.kycOption = event.target.value;
    switch (event.target.value) {
      case 'Pending':
        this.kycStatus = ["P"];
        break;
      case 'Verified':
        this.kycStatus = ["S"];
        break;
      case 'Failed':
        this.kycStatus = ["F"];
        break;
      default:
        this.kycStatus = ["F", "P", "S"];
        break;
    }
    this.getAccountDetails();
  }

  searching() {
    this.getAccountDetails();
  }

  sort(key: string, date: boolean = false) {
    this.reverse = !this.reverse;
    this.filteredAccountArr = this.sortArr(this.filteredAccountArr, key, this.reverse, date);
  }

  reset() {
    this.search = "";
    this.ngOnInit();
  }

  viewDetails(client: Clients) {
    this.router.navigate(['/view-account'], { state: { client: client } });
  }

  createAccount() {
    this.router.navigate(['/create-account']);
  }
}