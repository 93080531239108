<div class="modal-content">
    <div class="modal-header border-0">
        <h5 class="modal-title" id="modalLabel"></h5>
        <button #closeModalButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <h2 class="fw-bold">Successful</h2>

            <p *ngIf="this.data.transactionType == 'Top Up' && this.data.action == 'Y'">
                Received payment from client, system will send an email to inform client we
                <span class="approve">successful</span> top up their wallet.
            </p>
            <p *ngIf="this.data.transactionType == 'Top Up' && this.data.action == 'N'">
                Successful update status, will trigger an email to inform client top up transaction is
                <span class="reject">not successful</span>.
            </p>

            <p *ngIf="this.data.transactionType == 'Withdrawal' && this.data.action == 'Y'">
                Succesful verify client withdrawal details , system will send an email to inform client.
            </p>
            <p *ngIf="this.data.transactionType == 'Withdrawal' && this.data.action == 'N'">
                Succesful rejected client withdrawal details , system will send an email to inform client.
            </p>
        </div>

        <br>
        <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
                <app-button name="Done" (onClicked)="done()"></app-button>
            </div>
        </div>
    </div>
</div>