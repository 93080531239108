import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';;
import { LoginComponent } from './pages/auth/login/login.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { ViewWalletComponent } from './pages/wallet/view-wallet/view-wallet.component';
import { CurrencyRateComponent } from './pages/currency-rate/currency-rate.component';
import { StockComponent } from './pages/stock/stock.component';
import { MarketOrderComponent } from './pages/market-order/market-order.component';
import { AccountComponent } from './pages/account/account.component';
import { ViewAccountComponent } from './pages/account/view-account/view-account.component';
import { SettingComponent } from './pages/setting/setting.component';
import { CreateAcountComponent } from './pages/account/create-acount/create-acount.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'account', component: AccountComponent },
  { path: 'view-account', component: ViewAccountComponent },
  { path: 'create-account', component: CreateAcountComponent },
  { path: 'wallet', component: WalletComponent },
  { path: 'view-wallet', component: ViewWalletComponent },
  { path: 'currency-rate', component: CurrencyRateComponent },
  { path: 'stock', component: StockComponent },
  { path: 'market-order', component: MarketOrderComponent },
  { path: 'setting', component: SettingComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
