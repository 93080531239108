<div class="container-fluid">
    <h1 class="fw-bolder mb-0">Wallet</h1>
    <p class="text-muted">Manage and view client wallet details from here.</p>

    <div class="row align-items-center">
        <div *ngFor="let filter of walletFilter" class="col-12 col-md-auto px-1 py-1">
            <div class="row m-1 p-2 border rounded filter-option" [ngClass]="{ 'selected' : filter.isSelected }" (click)="filtering(filter)">
                {{ filter.option }}
            </div>
        </div>
    </div>

    <div class="row align-items-center">
        <div class="col-12 col-md-4 p-2">
            <div class="input-group">
                <div class="input-group-text" style="background-color: white; border-right: none;">
                    <span class="bi bi-search"></span>
                </div>
                <input type="input-group-text" class="form-control" style="border-left:none" placeholder="Search Client Name" [(ngModel)]="search" (ngModelChange)="searching()">
            </div>
        </div>
        <div class="col-12 col-md-auto p-2">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" [(ngModel)]='checkForPendingTrasaction' (ngModelChange)='onCheckboxChange($event)'>
                <label class="form-check-label text-muted" for="flexCheckDefault">
                    Only Display Pending Verify Request
                </label>
            </div>
        </div>
    </div>
    <div class="row">
        <form class="row row-cols-sm-auto">
            <div class="col-12 p-2">
                <div class="dp-hidden position-absolute">
                    <div class="input-group">
                        <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1"
                        [markDisabled]="isDateDisabled" [hidden]="!datepicker.isOpen()"/>
                        <ng-template #t let-date let-focused="focused">
                            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                {{ date.day }}
                            </span>
                        </ng-template>
                    </div>
                </div>
                <div class="input-group">
                    <input #dpFromDate class="form-control" placeholder="From" name="dpFromDate" [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)" />
                    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
                </div>
            </div>
            <div class="col-12 p-2">
                <div class="input-group">
                    <input #dpToDate class="form-control" placeholder="To" name="dpToDate" [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)" />
                    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
                </div>
            </div>
        </form>
    </div>

    <div class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th class="wd-10p border-bottom-0" (click)="sort('txType')">Transaction Type&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('txDate')">Transaction Date&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('bankAccountNo')">Account No.&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-20p border-bottom-0" (click)="sort('userWallet.user.firstName')">First Name&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('userWallet.user.lastName')">Last Name&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('userWallet.user.emailAddress')">Email Address&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-20p border-bottom-0" (click)="sort('amount')">Amount&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('isPaymentSlipUploaded')">Uploaded Payment Slip?&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('accountNo')">Verify Status&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0">Actions</th>
                </tr>
            </thead>
            <tbody *ngIf="filteredDataTable.length > 0">
                <tr *ngFor="let table of filteredDataTable | orderBy: key: reverse | paginate: { itemsPerPage:10, currentPage:p };">
                    <td>{{ table.txType }}</td>
                    <td>{{ table.txDate | date:'d MMM y' }}</td>
                    <td>{{ table.accountNo }}</td>
                    <td style="color:#3080E8">{{ table.userWallet.user.firstName }}</td>
                    <td style="color:#3080E8">{{ table.userWallet.user.lastName }}</td>
                    <td style="color:#3080E8">{{ table.userWallet.user.emailAddress }}</td>
                    <td>{{ table.currencyFrom }} {{ table.amount | number:'1.2-2' }}</td>
                    <td>{{ table.isPaymentSlipUploaded }}</td>
                    <td class="status">
                        <span class="p-1" style="margin-top: 9px;" [ngClass]="{ 'processing' : table.status === 'Processing', 'pending' : table.status === 'Pending',
                        'successful' : table.status === 'Verified', 'failed' : table.status === 'Failed' }">{{ table.status }}</span>
                    </td>
                    <td><button type="button" class="btn btn-primary btn-sm" (click)="viewDetails(table)">View Details</button></td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center">
            <span class="pt-1" style="font-size: 15px;">Showing {{ calculateRange(p, itemsPerPage, totalItems) }}</span>
            <pagination-controls style="font-size: 15px;" (pageChange)="p = $event" previousLabel="" nextLabel="" class="my-pagination"></pagination-controls>
        </div>
    </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
