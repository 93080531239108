import { Component, Input, OnInit } from '@angular/core';
import { BasePage } from '../../base.page';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';

@Component({
  selector: 'app-preview-order',
  templateUrl: './preview-order.component.html',
  styleUrls: ['./preview-order.component.scss']
})
export class PreviewOrderComponent extends BasePage implements OnInit {

  @Input() public data: any;
  hidePassword: boolean = true;
  previewOrderForm!: FormGroup;

  constructor(
    private modalService: ModalService,
    private apiService: ApiService,
    private router: Router,
    private toastrService: ToastrService,
    private storageService: StorageService) {
    super();
  }

  override ngOnInit() {
  }

  closeModal() {
    this.modalService.dismiss();
  }

  order() {
    this.loading = true;
    if (!this.data.isOtc) {
      this.apiService.orderbook(this.data.payload).subscribe({
        next: (data: any) => {
          this.loading = false;
          if (data.statusCode === 201 && data.message === 'Place order successful.') {
            this.closeModal();
            this.storageService.setTradeEvent(true);
          }
        },
        error: (error: ApiErrorResponse) => {
          this.loading = false;
          this.toastrService.error(error.error.message, "Error");
        }
      })
    } else {
      this.apiService.orderbookOtc(this.data.payload).subscribe({
        next: (data: any) => {
          this.loading = false;
          if (data.statusCode === 201 && data.message === 'Place OTC order successful.') {
            this.closeModal();
            this.storageService.setTradeEvent(true);
          }
        },
        error: (error: ApiErrorResponse) => {
          this.loading = false;
          this.toastrService.error(error.error.message, "Error");
        }
      })
    }
  }
}
