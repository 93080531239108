import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BasePage } from '../base.page';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PaginationInstance } from 'ngx-pagination';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent extends BasePage implements OnInit {

  // Variables
  itemsPerPage: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;
  reverse: boolean = false;
  config: PaginationInstance = {
    itemsPerPage: this.itemsPerPage,
    currentPage: this.currentPage,
    totalItems: this.totalItems
  };

  dataTable: any[] = [];
  filteredDataTable: any[] = [];
  search: string = '';
  emailAddress: string = ''
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  cancelledOrder: any;
  status: string = "All";

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    super();
    //this.fromDate = calendar.getPrev(calendar.getToday(), 'd', 30);
    //this.toDate = calendar.getToday();
      this.fromDate = null;
      this.toDate = null;
  }

  override ngOnInit(): void {
    this.cancelledOrder = {
      stock: {
        icon: '',
        name: '',
        code: ''
      },
      validity: '',
      orderType: '',
      price: '',
      qty: '',
      avgMatchedPrice: '',
      matchedQty: '',
      matchedAmt: '',
      status: ''
    };
    this.loading = true;
    this.searchOrderBook();
    this.cdr.detectChanges();
  }

  sort(key: string, date: boolean = false) {
    this.reverse = !this.reverse;
    this.filteredDataTable = this.sortArr(this.filteredDataTable, key, this.reverse, date);
  }

  pageChanged(event: any) {
    this.currentPage = event;
    this.searchOrderBook();
  }

  reset() {
    this.search = "";
    this.emailAddress = "";
    this.status = "All";
    this.ngOnInit();
  }

  searchOrderBook() {
    this.apiService.getOrderbook(this.search, this.emailAddress, null, this.status == 'All' ? null : this.status, this.getFormatDate(this.fromDate), this.getFormatDate(this.toDate), false, this.currentPage, this.itemsPerPage).subscribe({
      next: (data: any) => {
        this.totalItems = data.total;
        this.dataTable = data.data;

        this.dataTable = this.dataTable.filter((d: any) => {
          if (d.userWallet !== null) {
            d.createdAt = new Date(parseInt(d.createdAt));
            d.expiredAt = new Date(parseInt(d.expiredAt));
            d.action = d.action === 'bid' ? 'BUY' : 'SELL';
            d.type = d.type === 'limit' ? 'Limit Order' : 'Market Order';
            d.status = d.status === 'open' ? 'Queuing' : '';
            d.avgMatchedPrice = 0.00;
            d.matchedQuantity = 0;
            d.matchedAmount = 0.00;
            d.fees = 0.00;
            d.totalAmount = (d.qty * parseFloat(d.price)) + d.fees;

            if (d.userWallet !== null) {
              // d.accountNo = 'BJX' + d.userWallet.user.id;
              d.emailAddress = d.userWallet.user.emailAddress;
              d.firstName = d.userWallet.user.firstName;
              d.lastName = d.userWallet.user.lastName;
            }

            if (d.orderPosition !== null) {
              d.fees = d.orderPosition.fees;
              d.status = d.orderPosition.status == 'cancelled' ? 'Cancelled' : 'Matched';
              let totalMatchedPrice = 0.00;
              let matchedQuantity = 0;
              let orderAmount = 0;
              let matchAmount = 0.00;
              orderAmount = d.orderPosition.orderDetails.length;
              for (const od of d.orderPosition.orderDetails) {
                totalMatchedPrice += parseFloat(od.fillPrice);
                matchedQuantity += od.noOfShares;
                matchAmount += parseFloat(od.fillPrice) * od.noOfShares
              }
              d.avgMatchedPrice = Number.isNaN(totalMatchedPrice / orderAmount) ? 0.00 : totalMatchedPrice / orderAmount;
              d.matchedQuantity = matchedQuantity;
              d.matchedAmount = matchAmount;
            }
            return d;
          }
        })

        this.filteredDataTable = JSON.parse(JSON.stringify(this.dataTable));
        this.loading = false;
        this.cdr.detectChanges();
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }

  onStatusChange(event: any) {
    this.status = event.target.value;
    this.searchOrderBook();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.searchOrderBook();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  getFormatDate(date: any): number | null {
    if(date == null){
      return null;
    }
    const year: number = date.year;
    let month: number = date.month;
    let day: number = date.day + 1;
  
    // Check if adding 1 to the day exceeds the number of days in the month
    if (day > 31) {
      day = 1;
      // Increment the month, check if it exceeds 12 and increment the year if needed
      month += 1;
      if (month > 12) {
        date.year += 1;
        month = 1;
      }
    }
  
    // Adjust for months with 30 days
    if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30) {
      day = 1;
      month += 1;
    }
  
    // Adjust for February in leap years
    if (month === 2 && day > 28 + (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 1 : 0)) {
      day = 1;
      month += 1;
    }
  
    const formattedDate: string = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
    return new Date(formattedDate).getTime();
  }

  searching() {
    this.searchOrderBook();
    // this.filteredDataTable = JSON.parse(JSON.stringify(this.dataTable));
    // this.filteredDataTable = this.filteredDataTable.filter((d: any) => {
    //   if (this.search !== null) {
    //     return d.firstName.toLowerCase().includes(this.search.toLowerCase()) || d.lastName.toLowerCase().includes(this.search.toLowerCase());
    //   }
    //   return null;
    // })
  }

  isDateDisabled(date: NgbDateStruct): boolean {
    const today = new Date();
    const selectedDate = new Date(date.year, date.month - 1, date.day); // month is zero-indexed
    return selectedDate > today;
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}
