// Password must be between 8 and 20 characters, containing at least one lowercase letter, one uppercase letter, one numeric digit.
const PASSWORD_REGEXP = /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[\d.*?[#?!@$%^&*-;]){1,}).{8,}$/;
const MOBILE_SUFFIX = /^[0-9]{9,10}$/;
const EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const NUMBER = /^\d*(?:[.,]\d{1,2})?$/;
const POSTCODE = /^\d{5}$/;
const WHITESPACE = /^\s+(\S|\s)*$/;
const NRIC = /^(\d{2})(([1][0-2])|([0][1-9]))([0][1-9]|[1-2][0-9]|[3][0-1])-?\d{2}-?\d{4}$/;

export const REGEXVALIDATORS = {
    password: PASSWORD_REGEXP,
    mobile_suffix: MOBILE_SUFFIX,
    email: EMAIL,
    number: NUMBER,
    postcode: POSTCODE,
    whitespace: WHITESPACE,
    nric: NRIC
};
