<div class="container-fluid">
    <app-toast title="All Good!" subTitle="Successful update personal information." [show]="this.showToast"></app-toast>

    <h1 class="fw-bolder mb-0">Enquiry Account</h1>
    <p class="text-muted">Search and view your client accounts from here.</p>

    <div class="row m-2">
        <div class="col-3">
            <app-button name="Create User" (click)="createAccount()"></app-button>
        </div>
        <div class="col-9"></div>
    </div><br>

    <div class="row g-3 py-3 col-auto">
        <div class="form-group has-search col-md-5" style="position: relative;bottom: 13px; min-width: 100px;">
            <input type="text" class="form-control" placeholder="Search Client Name" [(ngModel)]="search" (ngModelChange)="searching()">
        </div>
        <div class="col-md-2 form-floating" style="position: relative;bottom: 13px; font-size: 15px; min-width: 150px">
            <select class="form-select" id="dropdownMenu" aria-label="Dropdown label" (change)="onKycOptionChange($event)">
                <option selected style="width: 30px;">All</option>
                <option value="Verified">Verified</option>
                <option value="Pending">Pending</option>
                <option value="Failed">Failed</option>
            </select>
            <span class="label text-muted">KYC Verified</span>
            <span class="label-selected">{{ kycOption }}</span>
        </div>
        <div class="col-md-1 gx-0">
            <button type="button" class="btn btn-outline-primary border-0" style="position: relative; bottom :13px;" (click)="reset()">Reset</button>
        </div>
    </div>

    <div class="mt-3 mb-3">
        <table class="table table-striped" style="position: relative; bottom: 36px;">
            <thead>
                <tr>
                    <th (click)="sort('id')">Account No.&nbsp;<i class="fas fa-sort"></i></th>
                    <th (click)="sort('firstName')">First Name&nbsp;<i class="fas fa-sort"></i></th>
                    <th (click)="sort('lastName')">Last Name&nbsp;<i class="fas fa-sort"></i></th>
                    <th (click)="sort('emailAddress')">Email Address&nbsp;<i class="fas fa-sort"></i></th>
                    <th (click)="sort('dob')">Date of Birth&nbsp;<i class="fas fa-sort"></i></th>
                    <th (click)="sort('country')">Country&nbsp;<i class="fas fa-sort"></i></th>
                    <th (click)="sort('createdDate',true)">Registered Date&nbsp;<i class="fas fa-sort"></i></th>
                    <th (click)="sort('kycStatus')">KYC Verified&nbsp;<i class="fas fa-sort"></i></th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody *ngIf="filteredAccountArr.length > 0 && !loading">
                <tr *ngFor="let account of filteredAccountArr | paginate: {itemsPerPage, currentPage, totalItems};">
                    <td>BJX{{ account.id }}</td>
                    <td style="color:#3080E8">{{ account.firstName }}</td>
                    <td style="color:#3080E8">{{ account.lastName }}</td>
                    <td style="color:#3080E8">{{ account.emailAddress }}</td>
                    <td>{{ account.dob | date:'dd MMM yyyy' }}</td>
                    <td>{{ account.country }}</td>
                    <td>{{ account.createdDate | date:'dd MMM yyyy':'UTC' }}</td>
                    <td><span class="second bg extra" [id]="account.kycStatus">{{ account.kycStatus }}</span></td>
                    <td><button type="button" class="btn btn-primary btn-sm" (click)="viewDetails(account)">View
                            Details</button></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="filteredAccountArr.length > 0 && !loading" class="d-flex justify-content-center">
        <span class="pt-1" style="font-size: 15px;">Showing {{ (currentPage - 1)*itemsPerPage + 1 }} -
            {{ (currentPage - 1)*itemsPerPage + filteredAccountArr.length }} of {{ totalItems }}</span>
        <pagination-controls style="font-size: 15px;" (pageChange)="pageChanged($event)" previousLabel="" nextLabel="" class="my-pagination"></pagination-controls>
    </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>