import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  data: any;

  constructor(private modalService: NgbModal) { }

  public open(modal: any, data: any = '', size: any = 'none'): void {
    if (this.modalService.hasOpenModals()) {
      this.dismiss();
    }
    const activeModal = this.modalService.open(modal, { animation: true, centered: true, size: size });
    activeModal.componentInstance.data = data;
  }

  public dismiss() {
    this.modalService.dismissAll();
  }
}
