<div class="container-fluid">
    <h1 class="fw-bolder mb-0">Exchange Rate</h1>
    <p class="text-muted">Manage USD conversion rate from here.</p>

    <div class="row align-items-center">
        <div *ngFor="let filter of currencyRateTypeFilter" class="col-12 col-md-auto px-1 py-1">
            <div class="row m-1 p-2 border rounded filter-option" [ngClass]="{ 'selected' : filter.isSelected }" (click)="filtering(filter)">
                {{ filter.option }}
            </div>
        </div>
    </div>

    <h3 class="fw-bolder mb-0">Currency Conversion Rate</h3>
    <form [formGroup]="currencyForm" class="mb-3">
        <div class="row align-items-center mb-2">
            <div class="col-5">
                <div class="input-group mb-2">
                    <div class="input-group-text"><span><img src="/assets/svgs/us-flag.svg" height="25px" width="45px"></span></div>
                    <input formControlName="currencyFrom" type="number" class="form-control" placeholder="USD US Dollar">
                </div>
            </div>
            <div class="col-auto"><span class="bi bi-arrow-left-right"></span></div>
            <div class="col-5">
                <div class="input-group mb-2">
                    <div class="input-group-text"><span><img src="/assets/svgs/malaysia-flag.svg" height="25px" width="45px"></span></div>
                    <input formControlName="currencyTo" type="number" class="form-control" placeholder="MYR Malaysia Ringgit" (blur)="convertAmount()">
                </div>
            </div>
        </div>

        <div class="row mb-2">
            <form class="row row-cols-sm-auto">
                <div class="col-12">
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker" [markDisabled]="isDateDisabled" />
                        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>
            </form>
        </div>

        <small *ngIf="currencyForm.get('currencyTo')!.invalid && (currencyForm.get('currencyTo')!.dirty || currencyForm.get('currencyTo')!.touched)" class="form-text error-message">
            Amount for MYR Malaysia Ringgit is required for USD US Dollar conversion</small>
        <div class="row">
            <h4 class="fw-bolder mb-0">1 USD = {{ currencyForm.get('currencyTo')?.value }} MYR</h4>
            <p class="text-muted">Base on your setting, this will be your system conversion rate.</p>
        </div><br>

        <div class="row">
            <div class="col-4">
                <app-button name="Set Rate" [disabled]="currencyForm.invalid" (onClicked)="setRate()"></app-button>
            </div>
        </div>
    </form><br>

    <h2 class="fw-bolder mb-2">Current Conversion History</h2>

    <div class="table-responsive" *ngIf="filteredDataTable.length > 0" >
        <table class="table table-striped">
            <thead>
                <tr>
                    <th class="wd-10p border-bottom-0" (click)="sort('applicableDate')">Date&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0">Currency Rate (USD ~ MYR)&nbsp;</th>
                    <th class="wd-15p border-bottom-0" (click)="sort('type')">Currency Rate Type&nbsp;</th>
                    <th class="wd-15p border-bottom-0" (click)="sort('updatedDate')">Created Date & Time&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-20p border-bottom-0" (click)="sort('updatedBy')">Created By&nbsp;<i class="fas fa-sort"></i></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let table of filteredDataTable | paginate: { itemsPerPage:10, currentPage:p };">
                    <td>{{ table.applicableDate | date:'d MMM y' }}</td>
                    <td>1 = {{ table.rate }}</td>
                    <td>{{ table.type }}</td>
                    <td>{{ table.createdDate | date:'dd-MMM-yyyy hh:mm:a' }}</td>
                    <td>{{ table.createdBy.username }}</td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center">
            <span class="pt-1" style="font-size: 15px;">Showing {{ calculateRange(p, itemsPerPage, totalItems) }}</span>
            <pagination-controls style="font-size: 15px;" (pageChange)="p = $event" previousLabel="" nextLabel="" class="my-pagination"></pagination-controls>
        </div>
    </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>