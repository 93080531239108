<div class="container-fluid">
    <h1 class="fw-bolder mb-0">Market Order</h1>
    <p class="text-muted">Place your order in this module.</p>

    <div class="row align-items-center">
        <div *ngFor="let filter of marketOrderFilter" class="col-12 col-md-auto px-1 py-1">
            <div class="row m-1 p-2 border rounded filter-option" [ngClass]="{ 'selected' : filter.isSelected }" (click)="filtering(filter)">
                {{ filter.option }}
            </div>
        </div>
    </div>

    <div *ngFor="let filter of marketOrderFilter">
        <!-- Order Book -->
        <div *ngIf="filter.option === 'Order Book' && filter.isSelected">
            <div class="row align-items-center">
                <div class="col-12 col-md-4 p-2">
                    <div class="input-group">
                        <div class="input-group-text" style="background-color: white; border-right: none;">
                            <span class="bi bi-search"></span>
                        </div>
                        <input type="input-group-text" class="form-control" style="border-left:none" placeholder="Search Stock Name" [(ngModel)]="search" (ngModelChange)="searching()">
                    </div>
                </div>
                <div class="col-12 col-md-2 p-2 form-floating" style="position: relative; bottom: -10px; font-size: 15px; min-width: 150px">
                    <select class="form-select" id="dropdownMenu" aria-label="Dropdown label" (change)="onStatusChange($event)">
                        <option selected style="width: 30px;">All</option>
                        <option value="Open">Open</option>
                        <option value="Closed">Closed</option>
                    </select>
                    <span class="label text-muted">Status</span>
                    <span class="label-selected">{{ status }}</span>
                </div>
                <div class="col-md-1 gx-0">
                    <button type="button" class="btn btn-outline-primary border-0" style="position: relative;" (click)="reset()">Reset</button>
                </div>
            </div>

            <div *ngIf="!loading" class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th class="wd-10p border-bottom-0" (click)="sort('createdAt')">Stock Name&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-15p border-bottom-0" (click)="sort('expiredAt')">Validity&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-15p border-bottom-0" (click)="sort('table.action')">Order Type&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-20p border-bottom-0" (click)="sort('price')">Price&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-20p border-bottom-0" (click)="sort('qty')">Qty&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-20p border-bottom-0" (click)="sort('avgMatchedPrice')">Avg Matched Price&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-20p border-bottom-0" (click)="sort('matchedQuantity')">Matched Qty&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-20p border-bottom-0" (click)="sort('matchedAmount')">Matched Amt&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-10p border-bottom-0">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let table of filteredDataTable | paginate: {itemsPerPage, currentPage, totalItems};">
                            <td>
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img height=40px width=40px src="{{ table.stock.iconPath }}">
                                    </div>
                                    <div class="col">
                                        <p class="fw-bolder m-0"><small>{{ table.stock.name }}</small></p>
                                        <p class="m-0"><small>{{ table.createdAt | date:'d MMM y h:mm:ss a' }}</small></p>
                                    </div>
                                </div>
                            </td>
                            <td>{{ table.expiredAt | date:'d MMM y' }}</td>
                            <td>
                                <div>
                                    <span *ngIf="table.action === 'BUY'" style="color: var(--brand-accent-2);">{{ table.action }}</span>
                                    <span *ngIf="table.action === 'SELL'" class="text-danger">{{ table.action }}</span>
                                    - {{ table.type }}
                                </div>
                            </td>
                            <td>{{ table.currency }} {{ stockPriceController(table.price) }}</td>
                            <td>{{ table.qty }}</td>
                            <td>{{ table.currency }} {{ stockPriceController(table.avgMatchedPrice) }}</td>
                            <td>{{ table.matchedQuantity }}</td>
                            <td>{{ table.currency }} {{ stockPriceController(table.matchedAmount) }}</td>
                            <td>
                                <span class="p-1 extra" [ngClass]="{ 'queuing' : table.status === 'Queuing', 'matched' : table.status === 'Matched',
                                'cancelled' : table.status === 'Cancelled', 'rejected' : table.status === 'Rejected' }">{{ table.status }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="!loading" class="d-flex justify-content-center">
                    <span class="pt-1" style="font-size: 15px;">Showing {{ (currentPage - 1)*itemsPerPage + 1 }} -
                        {{ (currentPage - 1)*itemsPerPage + filteredDataTable.length }} of {{ totalItems }}</span>
                    <pagination-controls style="font-size: 15px;" (pageChange)="pageChanged($event)" previousLabel="" nextLabel="" class="my-pagination"></pagination-controls>
                </div>
            </div>
        </div>

        <!-- Order Placement -->
        <div *ngIf="filter.option === 'Order Placement' && filter.isSelected">
            <div class="row">
                <div class="col-6 p-2">
                    <div class="input-group mb-2">
                        <div class="input-group-text"><span class="i bi-graph-up"></span></div>
                        <input list="stockDatalist" class="form-control" placeholder="Stock" [(ngModel)]="selectedStockSymbol" (change)="onChange($event)">
                        <datalist id="stockDatalist">
                            <select>
                                <option *ngFor="let s of this.availableStocks" [value]="s.symbol">{{ s.symbol }} {{ s.description }}</option>
                            </select>
                        </datalist>
                    </div>
                </div>
            </div>

            <div class="modal-body" *ngIf="this.selectedStock != null">
                <div class="container-fluid">
                    <div class="row align-items-center border-bottom p-2">
                        <div class="col-auto">
                            <img height=50px width=50px src="{{ this.selectedStock.icon }}">
                        </div>
                        <div class="col-auto">
                            <h5 class="mb-0 fw-bolder"><small>{{ this.selectedStock.description }}</small></h5>
                            <p class="mb-0"><small>{{ this.selectedStock.symbol }}</small></p>
                        </div>
                        <div class="col-auto">
                            <p class="mb-0 fw-bolder"><small>USD {{ stockPriceController(this.selectedStock.price) }}</small></p>
                            <div class="row fluctuation">
                                <p class="mb-0 fw-bolder" [ngClass]="{ 'no-fluctuation' : this.selectedStock.fluctuationPercent == 0, 'increase-fluctuation' : this.selectedStock.fluctuationPercent > 0, 'decrease-fluctuation' : this.selectedStock.fluctuationPercent < 0 }">
                                    <small>{{ percentageChangeController(this.selectedStock.fluctuationChange) }} ({{ percentageChangeController(this.selectedStock.fluctuationPercent) }}%)</small></p>
                            </div>
                        </div>
                        <div class="col-auto">
                            <p class="mb-0"><small>Day's Range</small></p>
                            <p class="mb-0 fw-bolder"><small>{{ stockPriceController(this.selectedStock.low) }} - {{ stockPriceController(this.selectedStock.high) }}</small></p>
                        </div>
                        <div class="col-auto">
                            <p class="mb-0"><small>Open</small></p>
                            <p class="mb-0 fw-bolder"><small>{{ stockPriceController(this.selectedStock.open) }}</small></p>
                        </div>
                        <div class="col-auto">
                            <p class="mb-0"><small>Prev. Close</small></p>
                            <p class="mb-0 fw-bolder"><small>{{ stockPriceController(this.selectedStock.prevClose) }}</small></p>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-12 col-lg-8 p-1">
                            <div class="tradingview-widget-container">
                                <app-trading-view [name]="this.selectedStock.name" [exchange]="this.selectedStock.exchange" [symbol]="this.selectedStock.symbol"></app-trading-view>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="row m-2 fw-bolder">
                                Actions
                            </div>
                            <div *ngIf="this.selectedStock.orderbook.bids.length > 0 && this.selectedStock.orderbook.asks.length > 0" class="row text-center p-1">
                                <div class="col-6 p-1">
                                    <div class="row align-items-center text-center p-1 orderbook-header">
                                        <div class="col-12 fw-bolder">Best Sell</div>
                                    </div>
                                    <div class="row align-items-center text-center p-1 orderbook-header">
                                        <div class="col-6 fw-bolder">Qty</div>
                                        <div class="col-6 fw-bolder">Price</div>
                                    </div>
                                    <div *ngFor="let b of this.selectedStock.orderbook.bids.slice(0, 3)" class="row align-items-center text-center p-1 orderbook-data">
                                        <div class="col-6 fw-bolder">{{ b.qty }}</div>
                                        <div class="col-6 fw-bolder" style="color: var(--brand-theme-lighter)">{{ stockPriceController(b.price) }}</div>
                                    </div>
                                </div>
                                <div class="col-6 p-1">
                                    <div class="row align-items-center text-center p-1 orderbook-header">
                                        <div class="col-12 fw-bolder">Best Buy</div>
                                    </div>
                                    <div class="row align-items-center text-center p-1 orderbook-header">
                                        <div class="col-6 fw-bolder">Qty</div>
                                        <div class="col-6 fw-bolder">Price</div>
                                    </div>
                                    <div *ngFor="let a of this.selectedStock.orderbook.asks.slice(0, 3)" class="row align-items-center text-center p-1 orderbook-data">
                                        <div class="col-6 fw-bolder">{{ a.qty }}</div>
                                        <div class="col-6 fw-bolder" style="color: var(--brand-primary)">{{ stockPriceController(a.price) }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-2 action">
                                <div class="col-6 p-1 text-center" [ngClass]="{ 'sell' : this.state == 0 }" (click)="sell()">
                                    <span>Sell</span>
                                </div>
                                <div class="col-6 p-1 text-center" [ngClass]="{ 'buy' : this.state == 1 }" (click)="buy()">
                                    <span>Buy</span>
                                </div>
                            </div>
                            <div class="row m-2">
                                <label class="form-label">OTC Purpose</label>
                                <div class="input-group mb-3 p-0">
                                    <div class="input-group-text"><span class="bi bi-person-plus"></span></div>
                                    <input list="userDatalist" class="form-control" placeholder="User (OTC Purpose)" [(ngModel)]="selectedUser">
                                    <datalist id="userDatalist">
                                        <select>
                                            <option *ngFor="let u of this.usersList" [value]="u.emailAddress">{{ u.lastName }} {{ u.firstName }}</option>
                                        </select>
                                    </datalist>
                                </div>
                            </div>
                            <form [formGroup]="orderPadForm">
                                <div class="row m-2">
                                    <label class="form-label">Price</label>
                                    <div class="input-group mb-3 p-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">USD</span>
                                        </div>
                                        <input formControlName="price" type="tel" class="form-control" style="border-right: 0;" (blur)="convertAmount()">
                                        <div class="minus-controller">
                                            <span class="bi bi-dash-circle" (click)="priceController('minus')"></span>
                                        </div>
                                        <div class="plus-controller">
                                            <span class="bi bi-plus-circle" (click)="priceController('plus')"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-2">
                                    <label class="form-label">Quantity (x1)</label>
                                    <div class="input-group mb-3 p-0">
                                        <input formControlName="quantity" type="number" class="form-control" style="border-right: 0;" (blur)="calculateTotalAmount()">
                                        <div class="minus-controller">
                                            <span class="bi bi-dash-circle" (click)="quantityController('minus')"></span>
                                        </div>
                                        <div class="plus-controller">
                                            <span class="bi bi-plus-circle" (click)="quantityController('plus')"></span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="this.selectedUser == ''" class="row m-2">
                                    <label class="form-label">Fees {{ this.orderSetting && this.orderSetting.commissionType === 'F' ? '(USD)' : '(%)' }}</label>
                                    <div class="input-group mb-3 p-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{ this.orderSetting && this.orderSetting.commissionType === 'F' ? 'USD' : '%' }}</span>
                                        </div>
                                        <input formControlName="fees" type="tel" class="form-control">
                                    </div>
                                </div>
                                <div class="row m-2">
                                    <label class="form-label">Validity</label>
                                    <input formControlName="validity" type="text" class="form-control mb-3">
                                </div>
                                <div class="row m-2">
                                    <label class="form-label">Total Amount (Including Fee)</label>
                                    <div class="input-group mb-3 p-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">USD</span>
                                        </div>
                                        <input formControlName="totalAmount" type="tel" class="form-control">
                                    </div>
                                </div>
                            </form><br>
                            <div class="row m-2">
                                <div class="col-12">
                                    <button class="mat-button" [ngClass]="{ 'sell' : this.state == 0, 'buy' : this.state == 1 }" [disabled]="orderPadForm.invalid" (click)="previewOrder()">Place Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<!-- Trade toast -->
<app-toast title="Well Done!" subTitle="You successfully place this stocks order." [show]="this.tradeToast" [success]=true></app-toast>