<!-- Preview Order Modal -->
<div class="modal-content">
    <div class="modal-header border-0">
        <h5 class="modal-title" id="modalLabel"></h5>
        <button #closeModalButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <h4 class="fw-bold">Preview Order</h4>
            <br>
            <div class="row align-items-center">
                <div class="col-auto">
                    <img height=75px width=75px src="{{ this.data.icon }}">
                </div>
                <div class="col">
                    <p class="fw-bolder m-0">{{ this.data.name }}</p>
                    <p class="m-0">{{ this.data.description }}</p>
                </div>
            </div><br>
            <div *ngIf="this.data.isOtc" class="row align-items-center p-2 fw-bolder preview-order-details">
                <div class="col-6">
                    <p class="m-0">User (OTC Purpose)</p>
                </div>
                <div class="col-6 text-end">
                    <p class="m-0">{{ this.data.emailAddress }}</p>
                </div>
            </div>
            <div class="row align-items-center p-2 fw-bolder preview-order-details">
                <div class="col-6">
                    <p class="m-0">Action</p>
                </div>
                <div class="col-6 text-end">
                    <p *ngIf="this.data.state === 0" class="m-0 text-danger">Sell Order</p>
                    <p *ngIf="this.data.state === 1" class="m-0" style="color: var(--brand-accent-2);">Buy Order</p>
                </div>
            </div>
            <div class="row align-items-center p-2 fw-bolder preview-order-details">
                <div class="col-6">
                    <p class="m-0">Validity</p>
                </div>
                <div class="col-6 text-end">
                    <p class="m-0">{{ this.data.validity }}</p>
                </div>
            </div>
            <div class="row align-items-center p-2 fw-bolder preview-order-details">
                <div class="col-6">
                    <p class="m-0">Price</p>
                </div>
                <div class="col-6 text-end">
                    <p class="m-0">{{ this.data.currency }} {{ this.data.price }}</p>
                </div>
            </div>
            <div class="row align-items-center p-2 fw-bolder preview-order-details">
                <div class="col-6">
                    <p class="m-0">Quantity</p>
                </div>
                <div class="col-6 text-end">
                    <p class="m-0">{{ this.data.quantity }}</p>
                </div>
            </div>
            <div class="row align-items-center p-2 fw-bolder preview-order-details">
                <div class="col-6">
                    <p class="m-0">Estimated Fee</p>
                </div>
                <div class="col-6 text-end">
                    <p class="m-0">{{ this.data.currency }} {{ this.data.fees }}</p>
                </div>
            </div>
            <div class="row align-items-center p-2 fw-bolder preview-order-details">
                <div class="col-6">
                    <p class="m-0">Total Amount (Including Fee)</p>
                </div>
                <div class="col-6 text-end">
                    <p class="m-0">{{ this.data.currency }} {{ this.data.totalAmount }}</p>
                </div>
            </div><br>
            <div class="row">
                <div class="col-6">
                    <app-button data-bs-dismiss="modal" name="Cancel" (onClicked)="closeModal()"></app-button>
                </div>
                <div class="col-6">
                    <app-button class="confirm-cancel-order-button" name="Confirm" (onClicked)="order()"></app-button>
                </div>
            </div>
        </div>
    </div>
</div>