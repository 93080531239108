import { Component, OnInit } from '@angular/core';
import { BasePage } from '../../base.page';
import { NavigationExtras, Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { REGEXVALIDATORS } from 'src/app/shared/validators/validators';
import { Location } from '@angular/common';
import { DateUtil } from 'src/app/shared/utils/DateUtil';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { UploadImageModule } from 'src/app/shared/enums/uploadImageModule';
import { forkJoin } from 'rxjs';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';

@Component({
  selector: 'app-create-acount',
  templateUrl: './create-acount.component.html',
  styleUrls: ['./create-acount.component.scss']
})
export class CreateAcountComponent extends BasePage implements OnInit {

  countryArray: Array<any> = [];
  kycStatusArry: Array<any> = [
    {
      code: "S",
      name: "Success"
    },
    {
      code: "F",
      name: "Fail"
    }
  ];
  createAccountForm!: FormGroup;
  minDate!: any;
  maxDate!: any;
  frontId?: Blob = undefined;
  frontIdFileName!: string;
  backId?: Blob = undefined;
  backIdFileName!: string;
  isFileSizeValid: boolean = true;
  isFileFormatValid: boolean = true;
  uploadedFrontIdUrl: string = '';
  uploadedBackIdUrl: string = '';
  showToast: boolean = false;

  constructor(
    private router: Router,
    private location: Location,
    private modalService: ModalService,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private dateUtil: DateUtil,
  ) {
    super();
  }

  override ngOnInit(): void {
    this.uploadedFrontIdUrl = '';
    this.uploadedBackIdUrl = '';
    this.getCountries();
    this.getDate();
    this.createAccountForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(REGEXVALIDATORS.email)
      ])),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      dateOfBirth: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      kycStatus: new FormControl('', Validators.required),
      kycRemarks: new FormControl('', Validators.required)
    });
  }

  getCountries() {
    this.apiService.getCountries().subscribe({
      next: (data: ApiResponse) => {
        this.countryArray = data.data.countryList;
      }
    })
  }

  getDate() {
    this.minDate = this.dateUtil.dateMetaData().minDate;
    this.maxDate = this.dateUtil.dateMetaData().maxDate;
  }

  uploadFrontId(files: File[]) {
    this.validateFileSize(files[0].size);
    this.frontId = files[0];
    this.frontIdFileName = files[0].name;
  }

  uploadBackId(files: File[]) {
    this.validateFileSize(files[0].size);
    this.backId = files[0];
    this.backIdFileName = files[0].name;
  }

  onDragOver(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  // From drag and drop
  onDropSuccess(e: any, view: string) {
    e.preventDefault();

    // notice the "dataTransfer" used instead of "target"
    if (this.validateFileFormat(e.dataTransfer.files[0].type)) {
      if (view === 'frontId') {
        this.uploadFrontId(e.dataTransfer.files);
      }
      if (view === 'backId') {
        this.uploadBackId(e.dataTransfer.files);
      }
    }
  }

  validateFileFormat(type: any): boolean {
    const validFileFormat = ['image/png', 'image/jpeg', 'image/jpg'];
    this.isFileFormatValid = validFileFormat.includes(type) ? true : false;
    return this.isFileFormatValid;
  }

  validateFileSize(size: any) {
    this.isFileSizeValid = +size >= 5120000 || +size <= 10000 ? false : true;
  }

  onSubmit() {
    if (this.frontId !== undefined || this.backId !== undefined) {
      const frontIdformData = new FormData();
      frontIdformData.append('file', this.frontId ? this.frontId : '');
      frontIdformData.append('fileName', this.frontIdFileName);
      frontIdformData.append('module', UploadImageModule.UPLOAD_USER_IC_FRONT);
      const uploadFrontIdFile = this.apiService.uploadImage(frontIdformData);

      const backIdformData = new FormData();
      backIdformData.append('file', this.backId ? this.backId : '');
      backIdformData.append('fileName', this.backIdFileName);
      backIdformData.append('module', UploadImageModule.UPLOAD_USER_IC_BACK);
      const uploadBackIdFile = this.apiService.uploadImage(backIdformData);

      forkJoin([uploadFrontIdFile, uploadBackIdFile]).subscribe({
        next: (results: any) => {
          if (results[0].statusCode === 201) {
            this.uploadedFrontIdUrl = results[1].data.url;
          }
          if (results[1].statusCode === 201) {
            this.uploadedBackIdUrl = results[1].data.url;
          }
          this.createUser();
        },
        error: (error: ApiErrorResponse) => {
          this.loading = false;
          this.toastrService.error(error.error.message, "Error");
        }
      });
    } else {
      this.createUser();
    }
  }

  createUser() {
    let payload = {
      emailAddress: this.createAccountForm.get('email')?.value,
      firstName: this.createAccountForm.get('firstName')?.value,
      lastName: this.createAccountForm.get('lastName')?.value,
      dob: this.createAccountForm.get('dateOfBirth')?.value.year + '-' +
        this.createAccountForm.get('dateOfBirth')?.value.month + '-' + this.createAccountForm.get('dateOfBirth')?.value.day,
      countryCode: this.countryArray.filter(c => this.createAccountForm.get('country')?.value === c.name).map(c => c.code)[0],
      kycStatus: this.kycStatusArry.filter(k => this.createAccountForm.get('kycStatus')?.value === k.name).map(k => k.code)[0],
      kycRemarks: this.createAccountForm.get('kycRemarks')?.value,
      frontIdPath: this.uploadedFrontIdUrl,
      backIdPath: this.uploadedBackIdUrl
    }
    this.apiService.createUser(payload).subscribe({
      next: (data: any) => {
        this.showToast = true;
        const navigationExtras: NavigationExtras = {
          queryParams: {
            showToast: this.showToast
          }
        };
        this.router.navigate(['/account'], navigationExtras);
      },
      error: (error: ApiErrorResponse) => {
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }
}
