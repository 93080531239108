import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Clients } from 'src/app/models/Client';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { DatePipe } from '@angular/common';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api/api.service';
import { PaginationInstance } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';
import { TransactionStatus } from 'src/app/shared/enums/transactionStatus';
import { TransactionType } from 'src/app/shared/enums/transactionType';
import { UploadImageModule } from 'src/app/shared/enums/uploadImageModule';
import { BasePage } from '../../base.page';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-view-account',
  templateUrl: './view-account.component.html',
  styleUrls: ['./view-account.component.scss']
})
export class ViewAccountComponent extends BasePage implements OnInit {

  client!: Clients;
  backupClient!: Clients;
  haveChange: boolean = false;
  clientService: ApiService;
  apiService: ApiService;
  countryArray: Array<any> = [];
  documentVerified: string = "Verified";
  documentValid: boolean = false;
  updateForm!: FormGroup;
  date: any;
  transactionList: any;
  userList: any[] = [];
  key: string = '';
  keyName: string = '';
  reverse: boolean = false;
  p: number = 1;
  itemsPerPage: number = 10;
  totalItems: number = 0;
  config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: this.itemsPerPage,
    currentPage: 1,
    totalItems: this.totalItems
  };
  showTransactionList = false;
  showToast: boolean = false;
  selectedOption: boolean = false;
  frontId?: Blob = undefined;
  frontIdFileName!: string;
  backId?: Blob = undefined;
  backIdFileName!: string;
  isFileSizeValid: boolean = true;
  isFileFormatValid: boolean = true;
  uploadedFrontIdUrl: string = '';
  uploadedBackIdUrl: string = '';
  portfolioList: any = [];

  constructor(
    injector: Injector,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private toastrService: ToastrService
  ) {
    super();
    this.clientService = injector.get(ApiService);
    this.apiService = injector.get(ApiService);

  }

  override ngOnInit(): void {
    this.client = history.state.client;
    this.backupClient = { ...this.client };
    this.getCountries();
    this.checkDocumentValid();
    // this.changeDateFormat();
    this.getClientDob();
    this.getUserTransaction();
    this.getUserPortfolio();
    this.uploadedFrontIdUrl = '';
    this.uploadedBackIdUrl = '';
  }

  back() {
    this.router.navigate(['/account']);
  }

  formatDate(date: NgbDateStruct): string {
    const year = date.year.toString().padStart(4, '0');
    const month = date.month.toString().padStart(2, '0');
    const day = date.day.toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getClientDob() {
    this.date = this.ngbDateParserFormatter.parse(this.client.dob);
    this.client.dob = this.date;
  }

  changeDateFormat() {
    this.client.dob = this.datePipe.transform(this.client.dob, 'yyyy-MM-dd')!;
  }

  checkEdit(fieldName: keyof Clients, value: any) {
    if (this.backupClient[fieldName] !== value) {
      this.haveChange = true;
    }
    // else {
    //   this.haveChange = false;
    // }
  }

  getCountries() {
    this.clientService.getCountries().subscribe({
      next: (data: ApiResponse) => {
        this.countryArray = data.data.countryList;
      }
    })
  }

  checkDocumentValid() {
    if (this.client.kycStatus == "Verified") {
      this.documentValid = true;
    }
    else {
      this.documentValid = false;
    }
  }

  onSubmit() {
    if (this.client.frontIdPath == null && this.client.backIdPath == null) {
      if (this.frontId == undefined || this.backId == undefined) {
        this.toastrService.error("Not able to upload due to missing front id and back id");
      }
    }

    const frontIdformData = new FormData();
    frontIdformData.append('file', this.frontId ? this.frontId : '');
    frontIdformData.append('fileName', this.frontIdFileName);
    frontIdformData.append('module', UploadImageModule.UPLOAD_USER_IC_FRONT);

    const backIdformData = new FormData();
    backIdformData.append('file', this.backId ? this.backId : '');
    backIdformData.append('fileName', this.backIdFileName);
    backIdformData.append('module', UploadImageModule.UPLOAD_USER_IC_BACK);

    if (this.frontId && this.backId) {
      const uploadFrontIdFile = this.apiService.uploadImage(frontIdformData);
      const uploadBackIdFile = this.apiService.uploadImage(backIdformData);
      forkJoin([uploadFrontIdFile, uploadBackIdFile]).subscribe({
        next: (results: any) => {
          if (results[0].statusCode === 201) {
            this.client.frontIdPath = results[1].data.url;
          }
          if (results[1].statusCode === 201) {
            this.client.backIdPath = results[1].data.url;
          }
          this.updateUser();
        },
        error: (error: ApiErrorResponse) => {
          this.loading = false;
          this.toastrService.error(error.error.message, "Error");
        }
      });
    } else if (this.frontId) {
      this.apiService.uploadImage(frontIdformData).subscribe({
        next: (data: any) => {
          if (data.statusCode === 201) {
            this.client.frontIdPath = data.data.url;
          }
          this.updateUser();
        },
        error: (error: ApiErrorResponse) => {
          this.toastrService.error(error.error.message, "Error");
        }
      })
    } else if (this.backId) {
      this.apiService.uploadImage(backIdformData).subscribe({
        next: (data: any) => {
          if (data.statusCode === 201) {
            this.client.backIdPath = data.data.url;
          }
          this.updateUser();
        },
        error: (error: ApiErrorResponse) => {
          this.toastrService.error(error.error.message, "Error");
        }
      })
    } else {
      this.updateUser();
    }
  }

  updateUser() {
    let countryCode;
    this.date = this.client.dob;
    this.client.dob = this.formatDate(this.date);
    let kycStatus: string = "";
    switch (this.client.kycStatus) {
      case 'Processing':
        kycStatus = "P";
        break;
      case 'Pending':
        kycStatus = "PA";
        break;
      case 'Verified':
        kycStatus = "S";
        break;
      case 'Failed':
        kycStatus = "F";
        break;
    }
    this.countryArray.forEach((country) => {
      if (country.name == this.client.country) {
        countryCode = country.code;
      }
    })

    const payload: any = {
      firstName: this.client.firstName,
      lastName: this.client.lastName,
      status: this.client.status,
      countryCode: countryCode,
      dob: this.client.dob,
      address: this.client.address,
      postalCode: this.client.postalCode,
      city: this.client.city,
      state: this.client.state,
      countryOfAddress: countryCode,
      frontIdPath: this.client.frontIdPath,
      backIdPath: this.client.backIdPath,
      kycStatus: kycStatus,
      kycRemarks: this.client.kycRemarks
    }

    this.clientService.updateUser(this.client.id, payload).subscribe({
      next: (data: any) => {
        this.showToast = true;
        const navigationExtras: NavigationExtras = {
          queryParams: {
            showToast: this.showToast
          }
        };
        this.router.navigate(['/account'], navigationExtras);
      },
      error: (error: ApiErrorResponse) => {
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }

  viewDetails(data: any) {
    const navExtras: NavigationExtras = {
      state: {
        data
      }
    }
    this.router.navigate(['/view-wallet'], navExtras);
  }

  calculateRange(p: number, itemsPerPage: number, totalItems: number): string {
    const start = Math.min((p - 1) * itemsPerPage + 1, totalItems);
    const end = Math.min(p * itemsPerPage, totalItems);
    return `${start}-${end} of ${totalItems} results`;
  }

  getUserTransaction() {
    this.apiService.getTransactionListByUserId(this.client.id).subscribe({
      next: (data: any) => {
        if (data.statusCode === 200) {
          this.transactionList = data.data;

          // get user's wallet balance
          this.client.walletAdjustment = "0.00";
          if (this.transactionList.length > 0) {
            this.client.walletAmount = Number(this.transactionList[this.transactionList.length - 1].userWallet.balance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          } else {
            this.client.walletAmount = "0.00";
          }

          this.transactionList.forEach((list: any) => {
            if (list.userWallet.id == this.client.userWallet[0].id) {
              list.accountNo = 'BJX' + list.id;
              this.userList.push(list);
              this.totalItems = this.userList.length;
            }
            list.txType = TransactionType[list.txType as keyof typeof TransactionType];
            list.status = TransactionStatus[list.status as keyof typeof TransactionStatus];
            list.isPaymentSlipUploaded = '-';
            list.accountNo = 'BJX' + list.id;
            if (list.txType == TransactionType.topup) {
              list.isPaymentSlipUploaded = list.walletTransactions.length > 1 ? 'Yes' : 'No';
            }
          })
        }
      },
      error: (error: ApiErrorResponse) => {
        // this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }

  sort(key: string, date: boolean = false) {
    this.key = key;
    this.reverse = !this.reverse;
    this.keyName = key;
    if (date) {
      this.userList.sort((a: any, b: any) => {
        let dateA = Date.parse(a[key]);
        let dateB = Date.parse(b[key]);
        return this.reverse ? dateB - dateA : dateA - dateB;
      });
    } else {
      this.userList.sort((a: any, b: any) => {
        return this.reverse ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);
      });
    }
  }

  uploadFrontId(files: File[]) {
    this.validateFileSize(files[0].size);
    this.frontId = files[0];
    this.frontIdFileName = files[0].name;
  }

  uploadBackId(files: File[]) {
    this.validateFileSize(files[0].size);
    this.backId = files[0];
    this.backIdFileName = files[0].name;
  }

  onDragOver(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  // From drag and drop
  onDropSuccess(e: any, view: string) {
    e.preventDefault();

    // notice the "dataTransfer" used instead of "target"
    if (this.validateFileFormat(e.dataTransfer.files[0].type)) {
      if (view === 'frontId') {
        this.uploadFrontId(e.dataTransfer.files);
      }
      if (view === 'backId') {
        this.uploadBackId(e.dataTransfer.files);
      }
    }
  }

  validateFileFormat(type: any): boolean {
    const validFileFormat = ['image/png', 'image/jpeg', 'image/jpg'];
    this.isFileFormatValid = validFileFormat.includes(type) ? true : false;
    return this.isFileFormatValid;
  }

  validateFileSize(size: any) {
    this.isFileSizeValid = +size >= 5120000 || +size <= 10000 ? false : true;
  }

  adjustWallet() {
    if (this.client.status == "P") {
      this.toastrService.error("User email verification is still pending", "Error");
    }
    if (this.client.walletAdjustment !== "" && this.client.status == "A" && this.client.kycStatus == "Verified") {
      const payload = {
        amount: this.client.walletAdjustment ? parseFloat(this.client.walletAdjustment.replace(/(,)/g, "").trim()) : 0.00,
        userId: this.client.id
      }
      this.clientService.walletAdjustment(payload).subscribe({
        next: (data: any) => {
          if (data.statusCode === 201) {
            this.showToast = true;
            const navigationExtras: NavigationExtras = {
              queryParams: {
                showToast: this.showToast
              }
            };
            this.router.navigate(['/account'], navigationExtras);
          }
        },
        error: (error: ApiErrorResponse) => {
          this.toastrService.error(error.error.message, "Error");
        }
      })
    }
  }

  getUserPortfolio() {
    this.apiService.getUserPortfolioByUserId(this.client.id).subscribe({
      next: (data: any) => {
        console.log("################")
        console.log("data: ", data)
        console.log("################")
        this.portfolioList = data.data;
      },
      error: (error: ApiErrorResponse) => {
        // this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }
}
