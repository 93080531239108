import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BasePage } from '../base.page';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationInstance } from 'ngx-pagination';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { Subscription, forkJoin } from 'rxjs';
import { Stock } from 'src/app/models/Stock';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderPadState } from 'src/app/shared/enums/orderPadState';
import { format } from 'date-fns';
import { PreviewOrderComponent } from './preview-order/preview-order.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TransactionStatus } from 'src/app/shared/enums/transactionStatus';

@Component({
  selector: 'app-market-order',
  templateUrl: './market-order.component.html',
  styleUrls: ['./market-order.component.scss']
})
export class MarketOrderComponent extends BasePage implements OnInit {

  parseFloat = parseFloat;
  @ViewChild('tradingview') tradingview?: ElementRef;

  // Variables
  itemsPerPage: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;
  reverse: boolean = false;
  config: PaginationInstance = {
    itemsPerPage: this.itemsPerPage,
    currentPage: this.currentPage,
    totalItems: this.totalItems
  };

  state?: OrderPadState = OrderPadState.BUY;
  orderPadForm!: FormGroup;
  marketOrderFilter: MarketOrderFilter[] = [
    {
      option: 'Order Book',
      isSelected: false
    },
    {
      option: 'Order Placement',
      isSelected: true
    }
  ]
  dataTable: any[] = [];
  filteredDataTable: any[] = [];
  search: string | null = null;
  status: string = "All";
  availableStocks: any[] = [];
  selectedStockSymbol: string = '';
  selectedStock: any = null;
  tradeSub!: Subscription;
  tradeToast: boolean = false;
  usersList: any[] = [];
  selectedUser: string = '';
  orderSetting: any

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private storageService: StorageService
  ) {
    super();
  }

  override ngOnInit(): void {
    this.orderPadForm = this.formBuilder.group({
      price: new FormControl('', Validators.required),
      quantity: new FormControl('', Validators.required),
      fees: new FormControl({ value: '', disabled: true }, Validators.required),
      validity: new FormControl({ value: '', disabled: true }, Validators.required),
      totalAmount: new FormControl({ value: '', disabled: true }, Validators.required)
    });
    this.tradeSub = this.storageService.getTradeEventAsObservable().subscribe(data => {
      if (data) {
        this.tradeToast = true;
        setInterval(() => {
          this.tradeToast = false;
        }, 5000);
      }
    })
    this.selectedOption();
  }

  sort(key: string, date: boolean = false) {
    this.reverse = !this.reverse;
    this.filteredDataTable = this.sortArr(this.filteredDataTable, key, this.reverse, date);
  }

  pageChanged(event: any) {
    this.currentPage = event;
    this.searchOrderBook();
  }

  onStatusChange(event: any) {
    this.status = event.target.value;
    this.searchOrderBook();
  }

  filtering(selectedFilter: MarketOrderFilter) {
    this.marketOrderFilter.filter((filter: MarketOrderFilter) => {
      return filter.isSelected = filter.option === selectedFilter.option ? true : false;
    });
    this.selectedOption();
  }

  selectedOption() {
    for (const f of this.marketOrderFilter) {
      if (f.option === 'Order Book' && f.isSelected) {
        this.searchOrderBook();
      }
      if (f.option === 'Order Placement' && f.isSelected) {
        this.state = OrderPadState.BUY;
        this.availableStocks = [];
        this.getStockInfo();
        this.getUserList();
      }
    }
  }

  searching() {
    this.filteredDataTable = JSON.parse(JSON.stringify(this.dataTable));
    this.filteredDataTable = this.filteredDataTable.filter((d: any) => {
      return d.stock.name.includes(this.search) || d.stock.symbol.includes(this.search);
    })
  }

  reset() {
    this.search = "";
    this.status = "All";
    this.ngOnInit();
  }

  getStockInfo() {
    const getDashboardStocks = this.apiService.getDashboardStocks();
    const getAvailableStocks = this.apiService.getAvailableStocks();

    forkJoin([getDashboardStocks, getAvailableStocks]).subscribe({
      next: (results: any) => {
        const dashboardStocks = results[0].data;
        const availableStocks = results[1].data;
        const resultArray = dashboardStocks.map((ds: any) => {
          const as = availableStocks.find((as: any) => as.symbol === ds.symbol);
          return {
            ...ds,
            ...as,
          };
        });

        for (const d of resultArray) {
          this.apiService.getStockCounter(d.symbol).subscribe({
            next: (data: any) => {
              let stock: Stock = {
                icon: d.iconPath,
                exchange: d.exchange,
                name: d.name,
                symbol: d.symbol,
                description: d.description,
                price: d.close,
                fluctuationChange: d.change,
                fluctuationPercent: parseFloat(d.percent_change),
                low: d.low,
                high: d.high,
                open: d.open,
                prevClose: d.previous_close,
                sell: data.data.bids.length === 0 ? d.close : data.data.bids.reduce((max: any, current: any) => {
                  return (max.price > current.price) ? max : current;
                }).price,
                buy: data.data.asks.length === 0 ? d.close : data.data.asks.reduce((min: any, current: any) => {
                  return (min.price < current.price) ? min : current;
                }).price,
                orderbook: data.data
              };
              this.availableStocks.push(stock);
            },
            error: (error: ApiErrorResponse) => {
              this.loading = false;
              this.toastrService.error(error.error.message, "Error");
            }
          })
        }
        this.loading = false;
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    });
  }

  searchOrderBook() {
    this.loading = true;
    this.apiService.getOrderbook('', '',  this.search, this.status == 'All' ? null : this.status, null, null, true, this.currentPage, this.itemsPerPage).subscribe({
      next: (data: any) => {
        this.totalItems = data.total;
        this.dataTable = data.data;

        this.dataTable = this.dataTable.filter((d: any) => {
          if (d.userWallet == null) {
            d.createdAt = new Date(parseInt(d.createdAt));
            d.expiredAt = new Date(parseInt(d.expiredAt));
            d.action = d.action === 'bid' ? 'BUY' : 'SELL';
            d.type = d.type === 'limit' ? 'Limit Order' : 'Market Order';
            d.status = d.status === 'open' ? 'Queuing' : '';
            d.avgMatchedPrice = 0.00;
            d.matchedQuantity = 0;
            d.matchedAmount = 0.00;
            d.fees = 1.00;
            d.totalAmount = (d.qty * parseFloat(d.price)) + d.fees;

            if (d.orderPosition !== null) {
              d.status = d.orderPosition.status == 'cancelled' ? 'Cancelled' : 'Matched';
              let totalMatchedPrice = 0.00;
              let matchedQuantity = 0;
              let orderAmount = 0;
              orderAmount = d.orderPosition.orderDetails.length;
              for (const od of d.orderPosition.orderDetails) {
                totalMatchedPrice += parseFloat(od.fillPrice);
                matchedQuantity += od.noOfShares;
              }
              d.avgMatchedPrice = Number.isNaN(totalMatchedPrice/orderAmount) ? 0.00 : totalMatchedPrice/orderAmount;
              d.matchedQuantity = matchedQuantity;
              d.matchedAmount = totalMatchedPrice;
            }
            return d;
          }
        })

        this.filteredDataTable = JSON.parse(JSON.stringify(this.dataTable));
        this.loading = false;
        this.cdr.detectChanges();
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }

  onChange(event: any) {
    this.selectedStock = null;
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.selectedStock = this.availableStocks.filter(s => {
        return s.symbol == this.selectedStockSymbol;
      })[0];
      this.state === OrderPadState.BUY ? this.buyOrderPadDetails() : this.sellOrderPadDetails();
    }, 1000);
  }

  priceController(operation: string) {
    if (operation === 'plus') {
      const editedAmount = parseFloat(this.selectedStock.price) > 1 ?
        (parseFloat(this.orderPadForm.get('price')?.value) + 0.01).toFixed(2) :
        (parseFloat(this.orderPadForm.get('price')?.value) + 0.0001).toFixed(4);
      this.orderPadForm.get('price')?.setValue(editedAmount);
      this.calculateTotalAmount();
    }
    if (operation === 'minus') {
      const editedAmount = parseFloat(this.selectedStock.price) > 1 ?
        (parseFloat(this.orderPadForm.get('price')?.value) - 0.01).toFixed(2) :
        (parseFloat(this.orderPadForm.get('price')?.value) - 0.0001).toFixed(4);
      this.orderPadForm.get('price')?.setValue(editedAmount);
      this.calculateTotalAmount();
    }
  }

  quantityController(operation: string) {
    if (operation === 'plus') {
      this.orderPadForm.get('quantity')?.setValue(this.orderPadForm.get('quantity')?.value + 1);
      this.calculateTotalAmount();
    }
    if (operation === 'minus') {
      const quantity = this.orderPadForm.get('quantity')?.value - 1 !== 0 ? this.orderPadForm.get('quantity')?.value - 1 : 1;
      this.orderPadForm.get('quantity')?.setValue(quantity);
      this.calculateTotalAmount();
    }
  }

  convertAmount() {
    let stringAmount = this.orderPadForm.get('price')?.value;
    this.orderPadForm.get('price')?.setValue(this.stockPriceController(stringAmount));
    this.calculateTotalAmount();
  }

  calculateTotalAmount() {
    const price = parseFloat(this.orderPadForm.get('price')?.value);
    const quantity = parseFloat(this.orderPadForm.get('quantity')?.value);
    const fees = parseFloat(this.orderPadForm.get('fees')?.value);
    let totalAmount;
    if (this.orderSetting.commissionType === 'F') {
      if (this.state === OrderPadState.BUY) {
        totalAmount = (price * quantity) + fees;
      } else {
        totalAmount = (price * quantity) - fees;
      }
    } else {
      if (this.state === OrderPadState.BUY) {
        totalAmount = (price * quantity) + ((price * quantity * fees) / 100);
      } else {
        totalAmount = (price * quantity) - ((price * quantity * fees) / 100);
      }
    }
    this.orderPadForm.get('totalAmount')?.setValue(this.stockPriceController(totalAmount));
  }
  
  sell() {
    this.state = OrderPadState.SELL;
    this.sellOrderPadDetails();
  }

  buy() {
    this.state = OrderPadState.BUY;
    this.buyOrderPadDetails();
  }

  buyOrderPadDetails() {
    const getOrderSetting = this.apiService.getOrderSetting();
    forkJoin([getOrderSetting]).subscribe({
      next: (results: any) => {
        const expiryDate = new Date(Date.now() + results[0].data.orderExpiration * 60 * 1000);
        const formattedExpiryDate = format(expiryDate, "dd MMM yyyy");
        this.orderPadForm.get('price')?.setValue(this.stockPriceController(this.selectedStock.buy));
        this.orderPadForm.get('quantity')?.setValue(1);
        this.orderPadForm.get('validity')?.setValue(formattedExpiryDate);
        this.orderSetting = results[0].data;
        this.orderPadForm.get('fees')?.setValue(parseFloat(this.orderSetting.commissionAmount).toFixed(2));
        this.calculateTotalAmount();
        this.loading = false;
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }
  
  sellOrderPadDetails() {
    const getOrderSetting = this.apiService.getOrderSetting();
    forkJoin([getOrderSetting]).subscribe({
      next: (results: any) => {
        const expiryDate = new Date(Date.now() + results[0].data.orderExpiration * 60 * 1000);
        const formattedExpiryDate = format(expiryDate, "dd MMM yyyy");   
        this.orderPadForm.get('price')?.setValue(this.stockPriceController(this.selectedStock.sell));
        this.orderPadForm.get('quantity')?.setValue(1);
        this.orderPadForm.get('validity')?.setValue(formattedExpiryDate);
        this.orderSetting = results[0].data;
        this.orderPadForm.get('fees')?.setValue(parseFloat(this.orderSetting.commissionAmount).toFixed(2));
        this.calculateTotalAmount();
        this.loading = false;
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }

  previewOrder() {
    let payload;
    if (this.selectedUser !== '') {
      payload = {
        noOfShares: this.orderPadForm.get('quantity')?.value,
        price: parseFloat(this.orderPadForm.get('price')?.value),
        currency: 'USD',
        orderAction: this.state === OrderPadState.BUY ? 'bid' : 'ask',
        stockSymbol: this.selectedStock.symbol,
        userId: this.usersList.filter((u: any) => { return u.emailAddress === this.selectedUser })[0].id
      }
      this.selectedStock.emailAddress = this.selectedUser;
    } else {
      payload = {
        noOfShares: this.orderPadForm.get('quantity')?.value,
        price: parseFloat(this.orderPadForm.get('price')?.value),
        currency: 'USD',
        orderType: 'limit',
        orderAction: this.state === OrderPadState.BUY ? 'bid' : 'ask',
        stockSymbol: this.selectedStock.symbol
      }
    }
    this.selectedStock.isOtc = this.selectedUser !== '' ? true : false;
    this.selectedStock.state = this.state;
    this.selectedStock.payload = payload;
    this.selectedStock.currency = 'USD';
    this.selectedStock.price = this.orderPadForm.get('price')?.value;
    this.selectedStock.quantity = this.orderPadForm.get('quantity')?.value;
    this.selectedStock.fees = this.orderPadForm.get('fees')?.value;
    this.selectedStock.validity = this.orderPadForm.get('validity')?.value;
    this.selectedStock.totalAmount = this.orderPadForm.get('totalAmount')?.value;
    this.modalService.open(PreviewOrderComponent, this.selectedStock);
  }

  getUserList() {
    this.apiService.getUserList().subscribe({
      next: (data: any) => {
        this.usersList = data.data.filter((user: any) => {
          return user.kycStatus == "S";
        })
      },
      error: (error: ApiErrorResponse) => {
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }
}

export interface MarketOrderFilter {
  option: string,
  isSelected: boolean
}