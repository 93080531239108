<div class="modal-content">
    <div class="modal-header border-0">
        <h5 class="modal-title" id="modalLabel"></h5>
        <button #closeModalButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <h2 class="fw-bold">Successful</h2>

            <p>
                Create currency conversion <span class="approve">successful</span>.
                <b>{{ this.data.responseData.currency_from }} 1 ~ {{ this.data.responseData.currency_to }} {{ this.data.responseData.rate }} </b>
                will start effective on <b>{{ this.data.responseData.applicableDate | date:'d MMM y' }}</b>
            </p>
        </div>

        <br>
        <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
                <app-button name="Done" (onClicked)="done()"></app-button>
            </div>
        </div>
    </div>
</div>