import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-approve-reject-transaction',
  templateUrl: './approve-reject-transaction.component.html',
  styleUrls: ['./approve-reject-transaction.component.scss']
})
export class ApproveRejectTransactionComponent implements OnInit {
  @Input() public data: any;

  constructor(
    private router: Router,
    private modalService: ModalService) {
  }

  ngOnInit() { }

  closeModal() {
    this.modalService.dismiss();
  }

  done() {
    this.modalService.dismiss();
    this.router.navigate(['/wallet']);
  }
}
