import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-create-currency-rate',
  templateUrl: './create-currency-rate.component.html',
  styleUrls: ['./create-currency-rate.component.scss']
})
export class CreateCurrencyRateComponent implements OnInit {
  @Input() public data: any;

  constructor(
    private router: Router,
    private modalService: ModalService) {
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalService.dismiss();
  }

  done() {
    this.modalService.dismiss();
    // window.location.reload();
    this.router.navigate(['/currency-rate']);
  }
}
