import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BasePage } from '../base.page';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent extends BasePage implements OnInit {

  settingArr: Setting[] = [];
  showToast: boolean = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  override ngOnInit() {
    this.loading = true;
    this.apiService.getSettings().subscribe({
      next: (data: any) => {
        this.settingArr = data.data;
        setTimeout(() => {
          this.loading = false;
          this.showToast = false;
        }, 1000);
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }

  updateSetting() {
    this.loading = true;
    const payload = {
      details: this.settingArr
    }
    this.apiService.updateSettings(payload).subscribe({
      next: (data: any) => {
        this.loading = false;
        if (data.statusCode === 200) {
          this.showToast = true;
          this.loading = false;
          this.ngOnInit();
        }
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }
}

export interface Setting {
  key: string;
  value: string;
}