<div class="container-fluid">
    <h1 class="fw-bolder mb-0">Settings</h1>
    <p class="text-muted">Manage system settings.</p>

    <div *ngFor="let setting of settingArr; let last = last" class="row align-items-center p-2">
        <div class="col-3 fw-bolder">
            {{ setting.key }}
        </div>
        <div class="col">
            <input type="text" class="form-control form-control-sm" [(ngModel)]="setting.value">
        </div>
        <div class="dashed-line mt-3 mx-auto" [class.d-none]="last"></div>
    </div><br>

    <div class="row">
        <div class="col-9"></div>
        <div class="col-3">
            <app-button name="Update Setting" (onClicked)="updateSetting()"></app-button>
        </div>
    </div><br>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<app-toast title="All Good!" subTitle="Update settings successfully" [show]="this.showToast"></app-toast>