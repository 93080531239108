import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl: string = '/api/v1';

  constructor(
    private http: HttpClient,
    private storageService: StorageService) { }

  private getHeader(contentType: string = 'application/json'): HttpHeaders {
    return contentType == 'multipart/form-data' ?
      new HttpHeaders({
        'Authorization': `Bearer ${this.storageService.getLoginEvent() !== null ? this.storageService.getLoginEvent().access_token : ''}`
      }) : new HttpHeaders({
        'Content-Type': contentType,
        'Authorization': `Bearer ${this.storageService.getLoginEvent() !== null ? this.storageService.getLoginEvent().access_token : ''}`
      });
  }

  getAuthUser(payload: any) {
    return this.http.post<any>(`${environment.apiUrl + this.baseUrl}/auth/admin`, payload);
  }

  getSettings() {
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/setting`, { headers: this.getHeader() });
  }

  updateSettings(payload: any) {
    return this.http.patch<any>(`${environment.apiUrl + this.baseUrl}/setting`, payload, { headers: this.getHeader() });
  }

  refreshToken() {
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/auth/refresh-token`, { headers: this.getHeader() });
  }

  getTransactionList(txType: string | null, status: string | null, startDate: string | null, endDate?: string | null) {
    let params = new HttpParams();
    params = params.set('orderSequence', 0);
    if (txType != null) {
      params = params.set('txType', txType);
    }
    if (status != null) {
      params = params.set('status', status);
    }
    if (startDate != null) {
      params = params.set('startDate', startDate);
    }
    if (endDate != null) {
      params = params.set('endDate', endDate);
    }
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/transaction/list`, { headers: this.getHeader(), params: params });
  }

  getTransactionListByUserId(userId: any) {
    let params = new HttpParams();
    params = params.set('orderSequence', 0);
    params = params.set('userId', userId);
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/transaction/list`, { headers: this.getHeader(), params: params });
  }

  getTransactionListAll() {
    let params = new HttpParams();
    params = params.set('orderSequence', 0);
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/transaction/list`, { headers: this.getHeader(), params: params });
  }

  updateTransaction(id: any, payload: any) {
    return this.http.patch<any>(`${environment.apiUrl + this.baseUrl}/transaction/update-request/${id}`, payload, { headers: this.getHeader() });
  }

  getCurrencyList() {
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/currency/list`, { headers: this.getHeader() });
  }

  createCurrencyList(payload: any) {
    return this.http.post<any>(`${environment.apiUrl + this.baseUrl}/currency`, payload, { headers: this.getHeader() });
  }

  getUser() {
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/user`, { headers: this.getHeader() });
  }

  getUserList() {
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/user/list`, { headers: this.getHeader() });
  }

  getUserListWithParams(search: string, kycStatus: string[], page: number, itemsPerPage: number) {
    let params = new HttpParams();
    params = params.set('orderBy', 'id');
    params = params.set('orderSequence', -1);
    params = params.set('page', page);
    params = params.set('limit', itemsPerPage);
    params = params.set('keyword', search);
    for (let i = 0; i < kycStatus.length; i++) {
      params = params.set(`kycStatus[${i}]`, kycStatus[i]);
    }
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/user/list`, { headers: this.getHeader(), params: params });
  }

  getCountries() {
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/user/get-registration-form`);
  }

  updateUser(id: any, payload: any) {
    return this.http.patch<any>(`${environment.apiUrl + this.baseUrl}/user/${id}`, payload, { headers: this.getHeader() });
  }

  getOrderbook(search: string, emailAddress: string, stockSymbol: string | null, status: string | null, startDate: number | null, endDate: number | null, isMarketMaker: boolean, page: number, itemsPerPage: number) {
    let params = new HttpParams();
    params = params.set('isMarketMaker', isMarketMaker);
    params = params.set('orderSequence', -1);
    params = params.set('page', page);
    params = params.set('limit', itemsPerPage);
    params = params.set('userName', search);
    params = params.set('emailAddress', emailAddress);
    if (stockSymbol != null) {
      params = params.set('stockSymbol', stockSymbol);
    }
    if (status != null) {
      params = params.set('status[0]', status);
    }
    if (startDate != null) {
      params = params.set('startDate', startDate);
    }
    if (endDate != null) {
      params = params.set('endDate', endDate);
    }
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/orderbook/list`, { headers: this.getHeader(), params: params });
  }

  getDashboardStocks() {
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/stock/get-dashboard-stocks`);
  }

  getAvailableStocks() {
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/stock/available`);
  }

  getOrderSetting() {
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/orderbook/setting`);
  }

  getStockCounter(stockSymbol: string) {
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/orderbook/counter/${stockSymbol}`);
  }

  orderbook(payload: any) {
    return this.http.post<any>(`${environment.apiUrl + this.baseUrl}/orderbook`, payload, { headers: this.getHeader() });
  }

  orderbookOtc(payload: any) {
    return this.http.post<any>(`${environment.apiUrl + this.baseUrl}/orderbook/otc`, payload, { headers: this.getHeader() });
  }

  uploadImage(payload: any) {
    return this.http.post<any>(`${environment.apiUrl + this.baseUrl}/general/upload-image`, payload, { headers: this.getHeader('multipart/form-data') });
  }

  walletAdjustment(payload: any) {
    return this.http.post<any>(`${environment.apiUrl + this.baseUrl}/transaction/wallet-adjustment`, payload, { headers: this.getHeader() });
  }

  createUser(payload: any) {
    return this.http.post<any>(`${environment.apiUrl + this.baseUrl}/user/admin-create`, payload, { headers: this.getHeader() });
  }

  getUserPortfolioByUserId(userId: any) {
    let params = new HttpParams();
    return this.http.get<any>(`${environment.apiUrl + this.baseUrl}/orderbook/portfolio/${userId}`, { headers: this.getHeader(), params: params });
  }
}
