import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PaginationInstance } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';
import { DataTable } from 'simple-datatables';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { ApiService } from 'src/app/services/api/api.service';
import { TransactionStatus } from 'src/app/shared/enums/transactionStatus';
import { TransactionType } from 'src/app/shared/enums/transactionType';
import { BasePage } from '../base.page';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent extends BasePage implements OnInit, AfterViewInit {

  TransactionType = TransactionType;
  dataTable: any[] = [];
  filteredDataTable: any[] = [];
  p: number = 1;
  itemsPerPage: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;
  key: string = '';
  keyName: string = '';
  reverse: boolean = false;
  config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: this.itemsPerPage,
    currentPage: 1,
    totalItems: this.totalItems
  };
  transactionTable?: DataTable;
  search: string = '';
  checkForPendingTrasaction: boolean = false;
  walletFilter: WalletFilter[] = [
    {
      option: 'All Client Wallet Information',
      isSelected: true
    },
    {
      option: 'Top Up Request',
      isSelected: false
    },
    {
      option: 'Withdrawal Request',
      isSelected: false
    }
  ]
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  isOpen: boolean = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    super();
    //this.fromDate = calendar.getPrev(calendar.getToday(), 'd', 30)
    //this.toDate = calendar.getToday();
      this.fromDate = null;
      this.toDate = null;
  }

  override ngOnInit(): void { 
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.loading = true;
    this.cdr.detectChanges();
    this.searchTransaction(this.getTransactionType(), this.getTransactionStatus(), this.getFormatDate(this.fromDate), this.getFormatDate(this.toDate));
  }

  getTransactionType(): string | null {
    let wf = this.walletFilter.find((w: WalletFilter) => {
      return w.isSelected === true;
    });

    if (wf) {
      if (wf?.option == 'All Client Wallet Information') {
        return null;
      } else if (wf?.option == 'Top Up Request') {
        return 'topup';
      } else if (wf?.option == 'Withdrawal Request') {
        return 'withdrawal';
      }
    }
    return null;
  }

  getTransactionStatus(): string | null {
    return this.checkForPendingTrasaction ? 'PA' : null;
  }

  getFormatDate(date: any): string | null {
    if(date == null){
      return null;
    }
    const year: number = date.year;
    let month: number = date.month;
    let day: number = date.day + 1;
  
    // Check if adding 1 to the day exceeds the number of days in the month
    if (day > 31) {
      day = 1;
      // Increment the month, check if it exceeds 12 and increment the year if needed
      month += 1;
      if (month > 12) {
        date.year += 1;
        month = 1;
      }
    }
  
    // Adjust for months with 30 days
    if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30) {
      day = 1;
      month += 1;
    }
  
    // Adjust for February in leap years
    if (month === 2 && day > 28 + (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 1 : 0)) {
      day = 1;
      month += 1;
    }
  
    const formattedDate: string = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
    return new Date(formattedDate).toISOString();
  }

  searchTransaction(txType: string | null, status: string | null, startDate: string | null, endDate: string | null) {
    this.apiService.getTransactionList(txType, status, startDate, endDate).subscribe({
      next: (data: any) => {
        if (data.statusCode === 200) {          
          this.dataTable = data.data;
          this.dataTable = this.dataTable.filter((d: any) => { 
            if (d.txType == 'topup' || d.txType == 'withdrawal') {
              d.txType = TransactionType[d.txType as keyof typeof TransactionType];
              d.status = TransactionStatus[d.status as keyof typeof TransactionStatus];
              d.isPaymentSlipUploaded = '-';
              d.accountNo = 'BJX' + d.id;
              if (d.txType == TransactionType.topup) {
                d.isPaymentSlipUploaded = d.walletTransactions.length > 1 ? 'Yes' : 'No';
              }       
              return d;       
            }             
          });
          this.totalItems = 0;
          if(this.dataTable){
            this.totalItems = this.dataTable.length;
          }          
          this.filteredDataTable = JSON.parse(JSON.stringify(this.dataTable));
          this.loading = false;
          this.cdr.detectChanges();
        }
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }

  filtering(selectedFilter: WalletFilter) {
    this.walletFilter.filter((filter: WalletFilter) => {
      return filter.isSelected = filter.option === selectedFilter.option ? true : false;
    });
    this.searchTransaction(this.getTransactionType(), this.getTransactionStatus(), this.getFormatDate(this.fromDate), this.getFormatDate(this.toDate));
  }

  searching() {
    this.filteredDataTable = JSON.parse(JSON.stringify(this.dataTable));
    
    this.filteredDataTable = this.filteredDataTable.filter((t: any) => {
      if (this.search !== null) {
        return t.userWallet.user.firstName.toLowerCase().includes(this.search.toLowerCase()) 
        || t.userWallet.user.lastName.toLowerCase().includes(this.search.toLowerCase()) 
        || t.userWallet.user.emailAddress.toLowerCase().includes(this.search.toLowerCase());
      }
      return null;
    })
  }

  onCheckboxChange(e: any) {
    this.checkForPendingTrasaction = e;
    this.searchTransaction(this.getTransactionType(), this.getTransactionStatus(), this.getFormatDate(this.fromDate), this.getFormatDate(this.toDate));
  }

  calculateRange(p: number, itemsPerPage: number, totalItems: number): string {
    const start = Math.min((p - 1) * itemsPerPage + 1, totalItems);    
    const end = Math.min(p * itemsPerPage, totalItems);
    return `${start}-${end} of ${totalItems} results`;
  }

  sort(key: string, date: boolean = false) {
    this.key = key;
    this.reverse = !this.reverse;
    this.keyName = key;
    if (date) {
      this.dataTable.sort((a: any, b: any) => {
        let dateA = Date.parse(a[key]);
        let dateB = Date.parse(b[key]);
        return this.reverse ? dateB - dateA : dateA - dateB;
      });
    } else {
      this.dataTable.sort((a: any, b: any) => {
        return this.reverse ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);
      });
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.searchTransaction(this.getTransactionType(), this.getTransactionStatus(), this.getFormatDate(this.fromDate), this.getFormatDate(this.toDate));
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  isDateDisabled(date: NgbDateStruct): boolean {
    const today = new Date();
    const selectedDate = new Date(date.year, date.month - 1, date.day); // month is zero-indexed
    return selectedDate > today;
  }

  viewDetails(data: any) {
    const navExtras: NavigationExtras = {
      state: {
        data
      }
    }
    this.router.navigate(['/view-wallet'], navExtras);
  }
}

export interface WalletFilter {
  option: string,
  isSelected: boolean
}