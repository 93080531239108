import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { WalletComponent } from './pages/wallet/wallet.component';
import { RefreshTokenComponent } from './pages/auth/refresh-token/refresh-token.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { ViewWalletComponent } from './pages/wallet/view-wallet/view-wallet.component';
import { CurrencyRateComponent } from './pages/currency-rate/currency-rate.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ApproveRejectTransactionComponent } from './pages/wallet/approve-reject-transaction/approve-reject-transaction.component';
import { CreateCurrencyRateComponent } from './pages/currency-rate/create-currency-rate/create-currency-rate.component';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { StockComponent } from './pages/stock/stock.component';
import { MarketOrderComponent } from './pages/market-order/market-order.component';
import { PreviewOrderComponent } from './pages/market-order/preview-order/preview-order.component';
import { ErrorInterceptor } from './services/interceptor/errorInterceptor';
import { AccountComponent } from './pages/account/account.component';
import { ViewAccountComponent } from './pages/account/view-account/view-account.component';
import { SettingComponent } from './pages/setting/setting.component';
import { CreateAcountComponent } from './pages/account/create-acount/create-acount.component';
import { DateUtil } from './shared/utils/DateUtil';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WalletComponent,
    RefreshTokenComponent,
    ForgotPasswordComponent,
    ViewWalletComponent,
    CurrencyRateComponent,
    ApproveRejectTransactionComponent,
    CreateCurrencyRateComponent,
    StockComponent,
    MarketOrderComponent,
    PreviewOrderComponent,
    AccountComponent,
    ViewAccountComponent,
    SettingComponent,
    CreateAcountComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    Ng2SearchPipeModule,
    OrderModule,
    NgxPaginationModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    PaginationModule.forRoot(),
    ToastrModule.forRoot({
    timeOut: 5000,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    maxOpened: 1
    }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: "rgba(0,0,0,0.1)",
      backdropBorderRadius: "4px",
      primaryColour: "#116650",
      secondaryColour: "#116650",
      tertiaryColour: "#116650",
    }),
    MatDatepickerModule,
    MatNativeDateModule,
    PdfViewerModule
  ],
  providers: [
    DateUtil,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {provide : LocationStrategy , useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
