import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PaginationInstance } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { REGEXVALIDATORS } from 'src/app/shared/validators/validators';
import { BasePage } from '../base.page';
import { CreateCurrencyRateComponent } from './create-currency-rate/create-currency-rate.component';

@Component({
  selector: 'app-currency-rate',
  templateUrl: './currency-rate.component.html',
  styleUrls: ['./currency-rate.component.scss']
})
export class CurrencyRateComponent extends BasePage implements OnInit, AfterViewInit {

  currencyForm!: FormGroup;
  date: NgbDateStruct;
  dataTable: any[] = [];
  filteredDataTable: any[] = [];
  p: number = 1;
  itemsPerPage: number = 10;
  totalItems: number = 0;
  key: string = '';
  keyName: string = '';
  reverse: boolean = false;
  config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: this.itemsPerPage,
    currentPage: 1,
    totalItems: this.totalItems
  };
  currencyRateTypeFilter: CurrencyRateTypeFilter[] = [
    {
      option: 'Deposit',
      isSelected: true
    },
    {
      option: 'Withdrawal',
      isSelected: false
    },
    {
      option: 'Trading',
      isSelected: false
    }
  ]

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private modalService: ModalService,
  ) {
    super();
    this.date = calendar.getToday();
  }

  override ngOnInit(): void {
    this.currencyForm = this.formBuilder.group({
      currencyFrom: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.pattern(REGEXVALIDATORS.number)]),
      currencyTo: new FormControl('', [Validators.required, Validators.pattern(REGEXVALIDATORS.number)])
    });
  }

  ngAfterViewInit() {
    this.loading = true;
    let defaultAmount: number = 1;
    this.currencyForm.get('currencyFrom')?.setValue(defaultAmount.toFixed(2));
    this.getAllCurrencyList();
  }

  getAllCurrencyList() {
    this.cdr.detectChanges();
    this.apiService.getCurrencyList().subscribe({
      next: (data: any) => {
        this.loading = false;
        if (data.statusCode === 200) {
          this.totalItems = data.data.length;
          this.dataTable = data.data;
          this.dataTable = this.dataTable.sort((a: any, b: any) => {
            let dateA = Date.parse(a.applicableDate);
            let dateB = Date.parse(b.applicableDate);
            return dateB - dateA;
          });
          this.filteredDataTable = JSON.parse(JSON.stringify(this.dataTable));
          this.selectedOption();
        }
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }

  convertAmount() {
    this.currencyForm.get('currencyTo')?.setValue(parseFloat(this.currencyForm.get('currencyTo')?.value).toFixed(2));
  }

  setRate() {
    this.loading = true;
    // Create a new Date object from the NgbDate object
    // const date: Date = new Date(this.date.year, this.date.month - 1, this.date.day);
    let type = '';
    for (const f of this.currencyRateTypeFilter) {
      if (f.isSelected) {
        switch (f.option) {
          case 'Deposit':
            type = 'DEPOSIT';
            break;
          case 'Withdrawal':
            type = 'WITHDRAWAL';
            break;
          case 'Trading':
            type = 'TRADING';
            break;
          default:
            // No filtering needed, keep the original data
            break;
        }
      }
    }

    // Convert the Date object to an ISO string
    const isoString: any = new Date().getDate() === this.date.day ? new Date(new Date().getTime() + 60000) :
      this.date.year + "-" + (this.date.month < 10 ? "0" + this.date.month : this.date.month) + "-" + this.date.day + "T00:00:00.000Z";
    const payload = {
      currencyFrom: "USD",
      currencyTo: "MYR",
      rate: parseFloat(this.currencyForm.get('currencyTo')?.value),
      applicableDate: isoString,
      type: type
    }
    this.apiService.createCurrencyList(payload).subscribe({
      next: (data: any) => {
        this.loading = false;
        if (data.statusCode === 201) {
          const modalData = {
            responseData: data.data
          }
          this.modalService.open(CreateCurrencyRateComponent, modalData);
          this.getAllCurrencyList();
        }
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  isDateDisabled(date: NgbDateStruct): boolean {
    const today = new Date();
    const selectedDate = new Date(date.year, date.month - 1, date.day + 1); // month is zero-indexed
    return selectedDate < today;
  }

  sort(key: string, date: boolean = false) {
    this.reverse = !this.reverse;
    this.dataTable = this.sortArr(this.dataTable, key, this.reverse, date);
  }

  calculateRange(p: number, itemsPerPage: number, totalItems: number): string {
    const start = Math.min((p - 1) * itemsPerPage + 1, totalItems);
    const end = Math.min(p * itemsPerPage, totalItems);
    return `${start}-${end} of ${totalItems} results`;
  }

  filtering(selectedFilter: CurrencyRateTypeFilter) {
    this.currencyRateTypeFilter.forEach((filter: CurrencyRateTypeFilter) => {
      filter.isSelected = filter.option === selectedFilter.option;
    });
    this.selectedOption();
  }

  selectedOption() {
    this.filteredDataTable = JSON.parse(JSON.stringify(this.dataTable));

    for (const f of this.currencyRateTypeFilter) {
      if (f.isSelected) {
        switch (f.option) {
          case 'Deposit':
            this.filteredDataTable = this.filteredDataTable.filter((data: any) => data.type === 'DEPOSIT');
            break;
          case 'Withdrawal':
            this.filteredDataTable = this.filteredDataTable.filter((data: any) => data.type === 'WITHDRAWAL');
            break;
          case 'Trading':
            this.filteredDataTable = this.filteredDataTable.filter((data: any) => data.type === 'TRADING');
            break;
          default:
            // No filtering needed, keep the original data
            break;
        }
      }
    }
  }
}

export interface CurrencyRateTypeFilter {
  option: string,
  isSelected: boolean
}