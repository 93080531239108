<div class="container-fluid">
    <h1 class="fw-bolder mb-0">Create New Account</h1>
    <p class="text-muted">Create new account in this module.</p>

    <form [formGroup]="createAccountForm" class="mb-2">
        <div class="row justify-content-between">
            <label for="lastName" class="col-md-3 col-form-label col-form-label-sm fw-bolder">First Name</label>
            <div class="col-md-6">
                <input formControlName="firstName" type="text" class="form-control form-control-sm" id="lastName"
                    name="firstName">
            </div>
        </div>
        <div class="dashed-line mt-3"></div>

        <div class="row mt-3 justify-content-between">
            <label for="lastName" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Last Name</label>
            <div class="col-md-6">
                <input formControlName="lastName" type="text" class="form-control form-control-sm" id="firstName"
                    name="lastName">
            </div>
        </div>
        <div class="dashed-line mt-3"></div>

        <div class="row mt-3 justify-content-between">
            <label for="dateOfBirth" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Date of Birth</label>
            <div class="col-md-6">
                <div class="input-group">
                    <input formControlName="dateOfBirth" class="form-control form-control-sm" id="dateOfBirth" name="dateOfBirth"
                        ngbDatepicker #d="ngbDatepicker" [minDate]="this.minDate" [maxDate]="this.maxDate">

                    <button class="btn btn-outline-secondary btn-sm bi bi-calendar3" (click)="d.toggle()"
                        type="button"></button>
                </div>
            </div>
        </div>
        <div class="dashed-line mt-3"></div>

        <div class="row mt-3 justify-content-between">
            <label for="country" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Country</label>
            <div class="col-md-6">
                <select formControlName="country" class="form-select form-select-sm" id="dropdownMenu" aria-label="Dropdown label"
                    name="country">
                    <option *ngFor="let c of this.countryArray" [value]="c.name">{{ c.name }}</option>
                </select>
            </div>
        </div>
        <div class="dashed-line mt-3"></div>

        <div class="row mt-3 justify-content-between">
            <label for="email" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Email Address</label>
            <div class="col-md-6">
                <input formControlName="email" type="email" class="form-control form-control-sm" id="email" name="email">
            </div>
        </div>
        <div class="dashed-line mt-3"></div>

        <div class="row mt-3 justify-content-between">
            <label for="kycStatus" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Kyc Status</label>
            <div class="col-md-6">
                <select formControlName="kycStatus" class="form-select form-select-sm" id="dropdownMenu" aria-label="Dropdown label"
                    name="kycStatus">
                    <option *ngFor="let k of this.kycStatusArry" [value]="k.name">{{ k.name }}</option>
                </select>
            </div>
        </div>
        <div class="dashed-line mt-3"></div>

        <div class="row mt-3 justify-content-between">
            <label for="kycRemarks" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Remarks</label>
            <div class="col-md-6">
                <input formControlName="kycRemarks" type="text" class="form-control form-control-sm" id="kycRemarks"
                    name="kycRemarks">
            </div>
        </div>
        <div class="dashed-line mt-3"></div>

        <div class="row align-items-center py-2" style="color: var(--brand-theme);">
            <h2 class="fw-bolder mb-0">Supporting Document</h2>
        </div>

        <div>
            <div class="row">
                <p>File size must between 10kb and 5120kb in jpg/jpeg/png format</p>
            </div>
            <div class="row id-upload-section">
                <div class="col-6 border" (click)="frontIdFile.click()" (dragover)="onDragOver($event)"
                    (drop)="onDropSuccess($event, 'frontId')">
                    <div class="row text-center">
                        <span class="bi bi-upload"></span>
                        <p class="m-0"><small>Upload Front Page</small></p>
                        <p class="m-0"><small>{{ this.frontIdFileName == null ? "Click or drag file to this area to
                                upload" : this.frontIdFileName }}</small></p>
                    </div>
                </div>
                <input type="file" accept="image/jpg, image/jpeg, image/png" #frontIdFile
                    (change)="uploadFrontId($any($event.target).files)" hidden>
                <div class="col-6 border" (click)="backIdFile.click()" (dragover)="onDragOver($event)"
                    (drop)="onDropSuccess($event, 'backId')">
                    <div class="row text-center">
                        <span class="bi bi-upload"></span>
                        <p class="m-0"><small>Upload Back Page</small></p>
                        <p class="m-0"><small>{{ this.backIdFileName == null ? "Click or drag file to this area to
                                upload" : this.backIdFileName }}</small></p>
                    </div>
                </div>
                <input type="file" accept="image/jpg, image/jpeg, image/png" #backIdFile
                    (change)="uploadBackId($any($event.target).files)" hidden>
            </div>
            <div *ngIf="!this.isFileSizeValid || !this.isFileFormatValid " class="row">
                <small class="form-text error-message">
                    {{ !this.isFileSizeValid ? "Invalid file size" : !this.isFileFormatValid ? "Invalid file format,
                    only jpg/jpeg/png format accepted" : '' }}</small>
            </div>
        </div>

    </form>

    <div class="row">
        <div class="col-9"></div>
        <div class="col-3">
            <app-button name="Create New Account" (onClicked)="onSubmit()" [disabled]="createAccountForm.invalid"></app-button>
        </div>
    </div><br>

</div>