<div class="container-fluid">
    <div *ngIf="this.useMobileView" class="row">
        <div class="col-12 top-section text-center">
            <h2 class="greeting fw-bolder">Good Morning,</h2>
            <h1 class="title fw-bolder">BJXSecurities <span>
                    <h2>Market Backoffice</h2>
                </span></h1>
        </div>
    </div>

    <div class="row align-items-center h-100">
        <div *ngIf="!this.useMobileView" class="col-4 text-center h-100" style="background-color: var(--brand-theme);">
            <div class="left-section">
                <h2 class="greeting fw-bolder">Good Morning,</h2>
                <h1 class="title fw-bolder">BJXSecurities <span>
                        <h2>Market Backoffice</h2>
                    </span></h1>
            </div>
        </div>

        <div class="col-12 col-lg-8 text-center">
            <h2 class="fw-bolder">Welcome Back.</h2>
            <div class="row">
                <div class="col"></div>
                <div class="col-8">
                    <form [formGroup]="signInForm" class="mb-3">
                        <div class="col-auto">
                            <div class="input-group mb-2">
                                <div class="input-group-text"><span class="bi bi-person"></span></div>
                                <input formControlName="username" type="text" class="form-control" placeholder="Username">
                            </div>
                            <small *ngIf="signInForm.get('username')!.invalid && signInForm.get('username')!.dirty" class="form-text error-message">
                                Username is required</small>
                        </div>
                        <div class="col-auto">
                            <div class="input-group mb-2">
                                <div class="input-group-text"><span class="bi bi-lock"></span></div>
                                <input formControlName="password" style="border-right: transparent" type="password" class="form-control" placeholder="Password" [type]="hide ? 'password' : 'text'">
                                <div class="password-eye" (click)="hide = !hide"><span [ngClass]="{ 'bi bi-eye-slash' : !hide , 'bi bi-eye' : hide }"></span></div>
                            </div>
                            <small *ngIf="signInForm.get('password')!.invalid && (signInForm.get('password')!.dirty || signInForm.get('password')!.touched)" class="form-text error-message">
                                Password field is required</small>
                        </div>
                    </form>

                    <app-button name="Login" (onClicked)="login()"></app-button>

                    <p class="forgot-password mt-2"><small (click)="navToForgotPassword()">Forgot password?</small></p>
                </div>
                <div class="col"></div>
            </div>
        </div>
    </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>