import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TransactionType } from 'src/app/shared/enums/transactionType';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ApproveRejectTransactionComponent } from '../approve-reject-transaction/approve-reject-transaction.component';
import { ApiService } from 'src/app/services/api/api.service';
import { BasePage } from '../../base.page';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { ToastrService } from 'ngx-toastr';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-view-wallet',
  templateUrl: './view-wallet.component.html',
  styleUrls: ['./view-wallet.component.scss']
})
export class ViewWalletComponent extends BasePage implements OnInit {

  transactionDetails?: TransactionDetails;
  reason!: FormControl;

  constructor(
    private router: Router,
    private location: Location,
    private modalService: ModalService,
    private apiService: ApiService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  override ngOnInit(): void {
    this.reason = new FormControl('', Validators.required);
    this.transactionDetails = {
      id: history.state.data.id,
      type: history.state.data.txType,
      accountNumber: history.state.data.accountNo,
      kycVerification: history.state.data.userWallet.user.kycStatus,
      firstName: history.state.data.userWallet.user.firstName,
      lastName: history.state.data.userWallet.user.lastName,
      amount: history.state.data.currencyFrom + ' ' + history.state.data.amount.toLocaleString('en-US'),
      currencyTo: history.state.data.currencyTo,
      convertedAmount: history.state.data.currencyTo + ' ' + history.state.data.convertedAmount.toLocaleString('en-US'),
      date: history.state.data.txDate,
      status: history.state.data.status
    };
    if (history.state.data.txType === TransactionType.topup) {
      if (history.state.data.walletTransactions.length > 1) {
        this.transactionDetails.isPaymentSlipUploaded = history.state.data.isPaymentSlipUploaded;
        this.transactionDetails.fileUrlPath = history.state.data.walletTransactions[1].proofUrl;
        this.transactionDetails.slipUploadedDate = history.state.data.walletTransactions[1].actionDate;
        this.transactionDetails.remark = history.state.data.walletTransactions[1].notes;
      }
    }

    if (history.state.data.txType === TransactionType.withdrawal) {
      this.transactionDetails.bankAccountHolderName = history.state.data.accountHolderName;
      this.transactionDetails.bankName = history.state.data.bankName
      this.transactionDetails.bankAccountNumber = history.state.data.bankAccountNo;
    }
  }

  goBack() {
    this.location.back();
  }

  action(action: string) {
    this.loading = true;
    const payload = {
      approve: action,
      reason: this.reason?.value,
    }
    this.apiService.updateTransaction(this.transactionDetails?.id, payload).subscribe({
      next: (data: any) => {
        this.loading = false;
        if (data.statusCode === 200) {
          const modalData = {
            action,
            transactionType: this.transactionDetails?.type
          }
          this.modalService.open(ApproveRejectTransactionComponent, modalData);
        }
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }
}

export interface TransactionDetails {
  id: number,
  type: TransactionType | null,
  accountNumber: string,
  kycVerification: boolean,
  firstName: string,
  lastName: string,
  amount: string,
  currencyTo: string,
  convertedAmount: string,
  date: string,
  status: string,
  isPaymentSlipUploaded?: string,
  fileUrlPath?: any,
  slipUploadedDate?: string,
  remark?: string,
  bankAccountHolderName?: string;
  bankName?: string;
  bankAccountNumber?: string;
}