<div class="container-fluid">
    <app-toast title="All Good!" subTitle="Successful update personal information." [show]="this.showToast"></app-toast>
    <div>

        <h1 class="fw-bolder mb-0"><span class="bi bi-chevron-left back" (click)="back()"></span>&nbsp;Account Details
        </h1><br>

        <div class="row align-items-center py-2" style="color: var(--brand-theme);">
            <h2 class="fw-bolder mb-0">Personal Information</h2>
        </div>

        <form (ngSubmit)="onSubmit()">
            <div class="row justify-content-between">
                <label for="lastName" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Last Name</label>
                <div class="col-md-6">
                    <input type="text" class="form-control form-control-sm" id="lastName" [(ngModel)]="client.lastName"
                        name="lastName" (ngModelChange)="checkEdit('lastName',client.lastName)">
                </div>
            </div>
            <div class="dashed-line mt-3"></div>
            <div class="row mt-3 justify-content-between">
                <label for="firstName" class="col-md-3 col-form-label col-form-label-sm fw-bolder">First Name</label>
                <div class="col-md-6">
                    <input type="text" class="form-control form-control-sm" id="firstName"
                        [(ngModel)]="client.firstName" name="firstName"
                        (ngModelChange)="checkEdit('firstName',client.firstName)">
                </div>
            </div>
            <div class="dashed-line mt-3"></div>
            <div class="row mt-3 justify-content-between">
                <label for="dob" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Date of Birth</label>
                <div class="col-md-6">
                    <div class="input-group">
                        <input class="form-control form-control-sm" id="dob" name="dob" [(ngModel)]="client.dob"
                            [ngModelOptions]="{standalone: true}" ngbDatepicker #d="ngbDatepicker"
                            (onModelChange)="client.dob">

                        <button class="btn btn-outline-secondary btn-sm bi bi-calendar3" (click)="d.toggle()"
                            type="button"></button>
                    </div>
                </div>
            </div>
            <div class="dashed-line mt-3"></div>
            <div class="row mt-3 justify-content-between">
                <label for="nationality" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Nationality</label>
                <div class="col-md-6">
                    <select class="form-select form-select-sm" id="dropdownMenu" aria-label="Dropdown label"
                        [(ngModel)]="client.country" name="country"
                        (ngModelChange)="checkEdit('country',client.country)">
                        <option *ngFor="let c of this.countryArray" [value]="c.name">{{ c.name }}</option>
                    </select>
                </div>
            </div>
            <div class="dashed-line mt-3"></div>
            <div class="row mt-3 justify-content-between">
                <label for="email" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Email Address</label>
                <div class="col-md-6">
                    <input type="email" class="form-control form-control-sm" id="email"
                        [(ngModel)]="client.emailAddress" name="email"
                        (ngModelChange)="checkEdit('emailAddress',client.emailAddress)">
                </div>
            </div>
            <div class="dashed-line mt-3"></div>
            <div class="row mt-3 justify-content-between">
                <label for="kycStatus" class="col-md-3 col-form-label col-form-label-sm fw-bolder">KYC
                    Verification</label>
                <div class="col-md-6">
                    <span class="second bg extra" [id]="client.kycStatus">{{ client.kycStatus }}</span>
                </div>
            </div>
            <div class="dashed-line mt-3"></div>
            <!-- <div *ngIf="client.kycStatus == 'Verified' && client.status == 'A'" class="row mt-3 justify-content-between">
                <label for="walletAmount" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Wallet Amount (USD)</label>
                <div class="col-md-6">
                    <input type="tel" class="form-control form-control-sm" id="walletAmount" [(ngModel)]="client.walletAmount" name="walletAmount" (ngModelChange)="checkEdit('walletAmount',client.walletAmount)">
                </div>
            </div> -->
        </form>
    </div><br>

    <div>
        <div class="row align-items-center py-2" style="color: var(--brand-theme);">
            <h2 class="fw-bolder mb-0">Supporting Document</h2>
        </div>

        <div>
            <div class="row">
                <p>File size must between 10kb and 5120kb in jpg/jpeg/png format</p>
            </div>
            <div class="row id-upload-section">
                <div class="col-6 border" (click)="frontIdFile.click()" (dragover)="onDragOver($event)"
                    (drop)="onDropSuccess($event, 'frontId')">
                    <div class="row text-center">
                        <span class="bi bi-upload"></span>
                        <p class="m-0"><small>Upload Front Page</small></p>
                        <p class="m-0"><small>{{ this.frontIdFileName == null ? "Click or drag file to this area to
                                upload" : this.frontIdFileName }}</small></p>
                    </div>
                </div>
                <input type="file" accept="image/jpg, image/jpeg, image/png" #frontIdFile
                    (change)="uploadFrontId($any($event.target).files)" hidden>
                <div class="col-6 border" (click)="backIdFile.click()" (dragover)="onDragOver($event)"
                    (drop)="onDropSuccess($event, 'backId')">
                    <div class="row text-center">
                        <span class="bi bi-upload"></span>
                        <p class="m-0"><small>Upload Back Page</small></p>
                        <p class="m-0"><small>{{ this.backIdFileName == null ? "Click or drag file to this area to
                                upload" : this.backIdFileName }}</small></p>
                    </div>
                </div>
                <input type="file" accept="image/jpg, image/jpeg, image/png" #backIdFile
                    (change)="uploadBackId($any($event.target).files)" hidden>
            </div>
            <div *ngIf="!this.isFileSizeValid || !this.isFileFormatValid " class="row">
                <small class="form-text error-message">
                    {{ !this.isFileSizeValid ? "Invalid file size" : !this.isFileFormatValid ? "Invalid file format,
                    only jpg/jpeg/png format accepted" : '' }}</small>
            </div>
        </div><br />

        <div class="row justify-content-between">
            <img class="col-6" src="{{ client.frontIdPath }}">
            <img class="col-6" src="{{ client.backIdPath }}">
        </div>
        <div class="row p-2 col-md-6 mt-3">
            <label for="valid" class="col-md-5 col-form-label col-form-label-sm fw-bolder">Document
                Validity</label>
            <div class="col-md-7 col-form-label col-form-label-sm" *ngIf="!documentValid else invalid">
                <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                        [(ngModel)]="this.client.kycStatus" value="Verified">
                    <label class="form-check-label" for="flexRadioDefault1">
                        &nbsp;Valid
                    </label>
                </div>
                <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                        [(ngModel)]="this.client.kycStatus" value="Failed" checked>
                    <label class="form-check-label" for="flexRadioDefault2">
                        &nbsp;Invalid
                    </label>
                </div>
            </div>
            <ng-template #invalid>
                <div class="col-md-7 col-form-label col-form-label-sm">
                    Valid
                </div>
            </ng-template>
        </div>
        <div class="row p-2 col-md-6">
            <label for="remarks" class="col-md-5 col-form-label col-form-label-sm fw-bolder">Remarks</label>
            <div class="col-md-7">
                <input type="text" class="form-control form-control-sm" id="state" *ngIf="!documentValid else failed"
                    [(ngModel)]="client.kycRemarks" name="remarks">
                <ng-template #failed>
                    <div class="col-form-label col-form-label-sm">
                        {{ client.kycRemarks }}
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-9"></div>
            <div class="col-3">
                <app-button name="Update Information" (onClicked)="onSubmit()"></app-button>
            </div>
        </div><br>
    </div>

    <!-- Wallet Adjustment -->
    <div *ngIf="client.kycStatus == 'Verified'">
        <div class="row align-items-center py-2" style="color: var(--brand-theme);">
            <h2 class="fw-bolder mb-0">Wallet Information</h2>
        </div>
    
        <div class="row mt-3 justify-content-between">
            <label for="walletAmount" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Wallet Amount
                (USD)</label>
            <div class="col-md-6">
                <input type="tel" class="form-control form-control-sm" id="walletAmount" [(ngModel)]="client.walletAmount"
                    name="walletAmount" disabled>
            </div>
        </div>

        <div class="row mt-3 justify-content-between">
            <label for="walletAdjustment" class="col-md-3 col-form-label col-form-label-sm fw-bolder">Wallet Adjustment
                (USD)</label>
            <div class="col-md-6">
                <input type="tel" class="form-control form-control-sm" id="walletAdjustment" [(ngModel)]="client.walletAdjustment"
                    name="walletAdjustment">
            </div>
        </div><br>

        <div class="row">
            <div class="col-9"></div>
            <div class="col-3">
                <app-button [disabled]="client.walletAdjustment === '0.00'" name="Adjust Wallet" (onClicked)="adjustWallet()"></app-button>
            </div>
        </div><br>
    </div>

    <div *ngIf="userList.length > 0" class="row align-items-center py-2" style="color: var(--brand-theme);">
        <h2 class="fw-bolder mb-0">Transaction Histories</h2>
    </div>

    <div>
        <div class="table-responsive" *ngIf="userList.length > 0">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th class="wd-10p border-bottom-0" (click)="sort('txType')">Transaction Type&nbsp;<i
                                class="fas fa-sort"></i></th>
                        <th class="wd-15p border-bottom-0" (click)="sort('txDate')">Transaction Date&nbsp;<i
                                class="fas fa-sort"></i></th>
                        <th class="wd-15p border-bottom-0" (click)="sort('bankAccountNo')">Account No.&nbsp;<i
                                class="fas fa-sort"></i></th>
                        <th class="wd-20p border-bottom-0" (click)="sort('userWallet.user.firstName')">First
                            Name&nbsp;<i class="fas fa-sort"></i></th>
                        <th class="wd-15p border-bottom-0" (click)="sort('userWallet.user.lastName')">Last Name&nbsp;<i
                                class="fas fa-sort"></i></th>
                        <th class="wd-10p border-bottom-0" (click)="sort('userWallet.user.emailAddress')">Email
                            Address&nbsp;<i class="fas fa-sort"></i></th>
                        <th class="wd-20p border-bottom-0" (click)="sort('amount')">Amount&nbsp;<i
                                class="fas fa-sort"></i>
                        </th>
                        <th class="wd-10p border-bottom-0" (click)="sort('isPaymentSlipUploaded')">Uploaded Payment
                            Slip?&nbsp;<i class="fas fa-sort"></i></th>
                        <th class="wd-10p border-bottom-0" (click)="sort('accountNo')">Verify Status&nbsp;<i
                                class="fas fa-sort"></i></th>
                        <th class="wd-10p border-bottom-0" (click)="sort('accountNo')">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let table of userList | orderBy: key: reverse | paginate: { itemsPerPage:10, currentPage:p };">
                        <td>{{ table.txType }}</td>
                        <td>{{ table.txDate | date:'d MMM y' }}</td>
                        <td>{{ table.accountNo }}</td>
                        <td style="color:#3080E8">{{ table.userWallet.user.firstName }}</td>
                        <td style="color:#3080E8">{{ table.userWallet.user.lastName }}</td>
                        <td style="color:#3080E8">{{ table.userWallet.user.emailAddress }}</td>
                        <td>
                            <span *ngIf="table.txType === 'Investment' || table.txType === 'Withdrawal'">-</span>
                            <span *ngIf="table.txType === 'Top Up' || table.txType === 'Adjustment'">+</span>
                            {{ table.currencyFrom }} {{ table.amount | number:'1.2-2' }}</td>
                        <td>{{ table.isPaymentSlipUploaded }}</td>
                        <td class="status">
                            <span class="p-1" [ngClass]="{ 'processing' : table.status === 'Processing', 'pending' : table.status === 'Pending',
                            'successful' : table.status === 'Verified', 'failed' : table.status === 'Failed' }">{{
                                table.status }}</span>
                        </td>
                        <td><button [disabled]="table.txType === 'Investment'" type="button"
                                class="btn btn-primary btn-sm" (click)="viewDetails(table)">View
                                Details</button></td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-center">
                <span class="pt-1" style="font-size: 15px;">Showing {{ calculateRange(p, itemsPerPage, totalItems)
                    }}</span>
                <pagination-controls style="font-size: 15px;" (pageChange)="p = $event" previousLabel="" nextLabel=""
                    class="my-pagination"></pagination-controls>
            </div>
        </div>

        <div *ngIf="portfolioList.length > 0" class="row align-items-center py-2" style="color: var(--brand-theme);">
            <h2 class="fw-bolder mb-0">Portfolio</h2>
        </div>

        <div>
            <div class="table-responsive" *ngIf="portfolioList.length > 0">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th class="wd-10p border-bottom-0" (click)="sort('stockName')">Stock Name&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-15p border-bottom-0" (click)="sort('noOfShares')">Total Qty&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-20p border-bottom-0" (click)="sort('valueInUsd')">W.A.C&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-20p border-bottom-0" (click)="sort('price')">Last Price&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-20p border-bottom-0" (click)="sort('marketValue')">Market Value&nbsp;<i class="fas fa-sort"></i></th>
                            <th class="wd-20p border-bottom-0" (click)="sort('updatedBy')">Profit/ Loss&nbsp;<i class="fas fa-sort"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let portfolio of portfolioList | orderBy: key: reverse | paginate: { itemsPerPage:10, currentPage:p };">
                            <td>
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img height=40px width=40px src="{{ portfolio.icon_path }}">
                                    </div>
                                    <div class="col">
                                        <p class="fw-bolder m-0"><small>{{ portfolio.stockName }}</small></p>
                                        <p class="m-0"><small>{{ portfolio.stock_symbol }} | {{ portfolio.fullName }}</small></p>
                                    </div>
                                </div>
                            </td>
                            <td>{{ portfolio.noOfShares }}</td>
                            <td>{{ portfolio.currency }} {{ portfolio.valueInUsd | number:'1.2-2' }}</td>
                            <td>{{ portfolio.currency }} {{ portfolio.price | number:'1.2-2' }}</td>
                            <td>{{ portfolio.currency }} {{ portfolio.marketValue | number:'1.2-2' }}</td>
                            <td>
                                <div *ngIf="portfolio.pnl > 0" class="fw-bolder pnl-positive">+ {{ portfolio.currency }} {{ portfolio.pnl | number:'1.2-2' }} (+{{ portfolio.pnlPercentage | number:'1.2-2'}}%)</div>
                                <div *ngIf="portfolio.pnl == 0" class="fw-bolder">{{ portfolio.currency }} {{ portfolio.pnl | number:'1.2-2' }} ({{ portfolio.pnlPercentage | number:'1.2-2'}}%)</div>
                                <div *ngIf="portfolio.pnl < 0" class="fw-bolder pnl-negative">- {{ portfolio.currency }} {{ portfolio.pnl | number:'1.2-2' }} ({{ portfolio.pnlPercentage | number:'1.2-2'}}%)</div>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-center">
                    <span class="pt-1" style="font-size: 15px;">Showing {{ calculateRange(p, itemsPerPage, totalItems)
                        }}</span>
                    <pagination-controls style="font-size: 15px;" (pageChange)="p = $event" previousLabel="" nextLabel=""
                        class="my-pagination"></pagination-controls>
                </div>
            </div>
        </div>

    </div>