<div class="container-fluid" *ngIf="transactionDetails">
    <h1 class="fw-bolder mb-0"><span class="bi bi-chevron-left back" (click)="goBack()"></span>&nbsp;{{ transactionDetails.type }} Details</h1><br>

    <div class="row align-items-center p-2" style="color: var(--brand-theme);">
        <h2 class="fw-bolder mb-0">Client Details</h2>
    </div>

    <div class="row align-items-center p-2">
        <div class="col-3 fw-bolder">
            Account Number
        </div>
        <div class="col">
            {{ transactionDetails.accountNumber }}
        </div>
        <div class="dashed-line mt-3 mx-auto"></div>
    </div>

    <div class="row align-items-center p-2">
        <div class="col-3 fw-bolder">
            First Name
        </div>
        <div class="col">
            {{ transactionDetails.firstName }}
        </div>
        <div class="dashed-line mt-3 mx-auto"></div>
    </div>

    <div class="row align-items-center p-2">
        <div class="col-3 fw-bolder">
            last Name
        </div>
        <div class="col">
            {{ transactionDetails.lastName }}
        </div>
        <div class="dashed-line mt-3 mx-auto"></div>
    </div>

    <div class="row align-items-center p-2">
        <div class="col-3 fw-bolder">
            KYC Verification
        </div>
        <div class="col">
            {{ transactionDetails.kycVerification }}
        </div>
        <div class="dashed-line mt-3 mx-auto"></div>
    </div>

    <div class="row align-items-center p-2" style="color: var(--brand-theme);">
        <h2 class="fw-bolder mb-0">{{ transactionDetails.type }} Information</h2>
    </div>

    <div class="row align-items-center p-2">
        <div class="col-3 fw-bolder">
            {{ transactionDetails.type }} Amount
        </div>
        <div class="col">
            {{ transactionDetails.amount }}
        </div>
        <div class="dashed-line mt-3 mx-auto"></div>
    </div>

    <div class="row align-items-center p-2">
        <div class="col-3 fw-bolder">
            Estimated Converted to {{ transactionDetails.currencyTo }}
        </div>
        <div class="col">
            {{ transactionDetails.convertedAmount }}
        </div>
        <div class="dashed-line mt-3 mx-auto"></div>
    </div>

    <div class="row align-items-center p-2">
        <div class="col-3 fw-bolder">
            {{ transactionDetails.type }} Date
        </div>
        <div class="col">
            {{ transactionDetails.date | date:'d MMM y'}}
        </div>
        <div class="dashed-line mt-3 mx-auto"></div>
    </div>

    <div *ngIf="transactionDetails.type == 'Top Up' && transactionDetails.isPaymentSlipUploaded == 'Yes'" class="row align-items-center p-2">
        <div class="col-3 fw-bolder">
            Bank In Slip Uploaded Date
        </div>
        <div class="col">
            {{ transactionDetails.slipUploadedDate | date:'d MMM y' }}
        </div>
        <div class="dashed-line mt-3 mx-auto"></div>
    </div>

    <div *ngIf="transactionDetails.type == 'Top Up' && transactionDetails.isPaymentSlipUploaded == 'Yes'" class="row align-items-center p-2">
        <div class="col-12 fw-bolder mb-2">
            Uploaded File
        </div>
        <div class="col-auto">
            <pdf-viewer *ngIf="transactionDetails.fileUrlPath.indexOf('.pdf') != -1" [src]="transactionDetails.fileUrlPath" [render-text]="true" [show-borders]="true" [original-size]="false" class="responsive-image" style="width: 500px; height: 600px"></pdf-viewer>
            <img *ngIf="transactionDetails.fileUrlPath.indexOf('.pdf') == -1" [src]="transactionDetails.fileUrlPath" class="responsive-image">
        </div>
    </div>

    <div *ngIf="transactionDetails.type == 'Withdrawal'">
        <div class="row align-items-center p-2" style="color: var(--brand-theme);">
            <h2 class="fw-bolder mb-0">Bank Account Information</h2>
        </div>

        <div class="row align-items-center p-2">
            <div class="col-3 fw-bolder">
                Full name of the account holder
            </div>
            <div class="col">
                {{ transactionDetails.bankAccountHolderName }}
            </div>
            <div class="dashed-line mt-3 mx-auto"></div>
        </div>

        <div class="row align-items-center p-2">
            <div class="col-3 fw-bolder">
                Bank Name
            </div>
            <div class="col">
                {{ transactionDetails.bankName }}
            </div>
            <div class="dashed-line mt-3 mx-auto"></div>
        </div>

        <div class="row align-items-center p-2">
            <div class="col-3 fw-bolder">
                Account Number
            </div>
            <div class="col">
                {{ transactionDetails.bankAccountNumber }}
            </div>
            <div class="dashed-line mt-3 mx-auto"></div>
        </div>

        <div class="row align-items-center p-2">
            <div class="col-3 fw-bolder">
                Reason
            </div>
            <div class="col">
                <textarea class="form-control" [formControl]="reason" rows="3"></textarea>
            </div>
            <div class="dashed-line mt-3 mx-auto"></div>
        </div>
    </div>
    <br>

    <div class="row mb-3" *ngIf="(transactionDetails.status == 'Pending' && transactionDetails.isPaymentSlipUploaded == 'Yes') || 
    (transactionDetails.type == 'Withdrawal' && transactionDetails.status == 'Pending')">
        <div class="col-6"></div>
        <div class="col-3">
            <app-button name="Reject" class="reject" (onClicked)="action('N')"></app-button>
        </div>
        <div class="col-3">
            <app-button name="Approve" (onClicked)="action('Y')"></app-button>
        </div>
    </div>
    <br>
</div>