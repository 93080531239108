<div class="container-fluid">
    <h1 class="fw-bolder mb-0">Stock</h1>
    <p class="text-muted">View client stocks order details from here.</p>

    <div class="row align-items-center">
        <div class="col-12 col-md-4 p-2">
            <div class="input-group">
                <div class="input-group-text" style="background-color: white; border-right: none;">
                    <span class="bi bi-search"></span>
                </div>
                <input type="input-group-text" class="form-control" style="border-left:none" placeholder="Search Client Name" [(ngModel)]="search" (ngModelChange)="searching()">
            </div>
        </div>

        <div class="col-12 col-md-4 p-2">
            <div class="input-group">
                <div class="input-group-text" style="background-color: white; border-right: none;">
                    <span class="bi bi-search"></span>
                </div>
                <input type="input-group-text" class="form-control" style="border-left:none" placeholder="Search Client EmailAddress" [(ngModel)]="emailAddress" (ngModelChange)="searching()">
            </div>
        </div>

        <div class="col-12 col-md-2 p-2 form-floating" style="position: relative; bottom: -10px; font-size: 15px; min-width: 150px">
            <select class="form-select" id="dropdownMenu" aria-label="Dropdown label"
                (change)="onStatusChange($event)">
                <option selected style="width: 30px;">All</option>
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
            </select>
            <span class="label text-muted">Status</span>
            <span class="label-selected">{{ status }}</span>
        </div>
        <div class="col-md-1 gx-0">
            <button type="button" class="btn btn-outline-primary border-0" style="position: relative;"
                (click)="reset()">Reset</button>
        </div>
    </div>
    <div class="row">
        <form class="row row-cols-sm-auto">
            <div class="col-12 p-2">
                <div class="dp-hidden position-absolute">
                    <div class="input-group">
                        <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1"
                        [markDisabled]="isDateDisabled" [hidden]="!datepicker.isOpen()"/>
                        <ng-template #t let-date let-focused="focused">
                            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                {{ date.day }}
                            </span>
                        </ng-template>
                    </div>
                </div>
                <div class="input-group">
                    <input #dpFromDate class="form-control" placeholder="From" name="dpFromDate" [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)" />
                    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
                </div>
            </div>
            <div class="col-12 p-2">
                <div class="input-group">
                    <input #dpToDate class="form-control" placeholder="To" name="dpToDate" [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)" />
                    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="!loading" class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th class="wd-10p border-bottom-0" (click)="sort('transactionDate')">Transaction Date&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('emailAddress')">Email Address&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('firstName')">First Name&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('lastName')">Last Name&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('emailAddress')">EmailAddress&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('createdAt')">Stock Name&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('action')">Action&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('price')">Price&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('qty')">Qty&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('avgMatchedPrice')">Avg Matched Price&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0" (click)="sort('matchedQuantity')">Matched Qty&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('matchedAmount')">Matched Amt&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('fees')">Fees&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-10p border-bottom-0">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let table of filteredDataTable | paginate: {itemsPerPage, currentPage, totalItems};">
                    <td>{{ table.createdAt | date:'d MMM y' }}</td>
                    <td>{{ table.emailAddress }}</td>
                    <td>{{ table.firstName }}</td>
                    <td>{{ table.lastName }}</td>
                    <td>{{ table.userWallet.user.emailAddress }}</td>
                    <td>
                        <div class="row align-items-center">
                            <div class="col-auto">
                                <img height=40px width=40px src="{{ table.stock.iconPath }}">
                            </div>
                            <div class="col">
                                <p class="fw-bolder m-0"><small>{{ table.stock.name }}</small></p>
                                <p class="m-0"><small>{{ table.stock.description }}</small></p>
                            </div>
                        </div>
                    </td>
                    <td>{{ table.action }}</td>
                    <td>{{ table.currency }} {{ stockPriceController(table.price) }}</td>
                    <td>{{ table.qty }}</td>
                    <td>{{ table.currency }} {{ stockPriceController(table.avgMatchedPrice) }}</td>
                    <td>{{ table.matchedQuantity }}</td>
                    <td>{{ table.currency }} {{ stockPriceController(table.matchedAmount) }}</td>
                    <td> 
                        <span *ngIf="table.fees == 0.00">-</span>
                        <span *ngIf="table.fees > 0">{{ table.currency }} {{ stockPriceController(table.fees) }}</span>
                    </td>
                    <td>
                        <span class="p-1 extra" [ngClass]="{ 'queuing' : table.status === 'Queuing', 'matched' : table.status === 'Matched',
                        'cancelled' : table.status === 'Cancelled', 'rejected' : table.status === 'Rejected' }">{{ table.status }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center">
            <div *ngIf="!loading" class="d-flex justify-content-center">
                <span class="pt-1" style="font-size: 15px;">Showing {{ (currentPage - 1)*itemsPerPage + 1 }} -
                    {{ (currentPage - 1)*itemsPerPage + filteredDataTable.length }} of {{ totalItems }}</span>
                <pagination-controls style="font-size: 15px;" (pageChange)="pageChanged($event)" previousLabel="" nextLabel="" class="my-pagination"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>