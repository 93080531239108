import { Injectable } from '@angular/core';
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class DateUtil {
    // helper to find out today's year, month, day
    private currentDate() {
        var todayDate = new Date();

        return {
            year: todayDate.getFullYear(),
            month: todayDate.getMonth() + 1,
            day: todayDate.getDate()
        }
    }

    // helper to find out date value, min and max date
    dateMetaData() {
        var minDate = this.currentDate()
        var maxDate = this.currentDate()
        minDate.year = minDate.year - 100;
        maxDate.year = maxDate.year - 18;

        var value = new NgbDate(this.currentDate().year,
            this.currentDate().month + 2,
            this.currentDate().day)

        return {
            value: value,
            minDate: minDate,
            maxDate: maxDate
        }
    }
}