<div class="modal-content">
    <div class="modal-header border-0">
        <h5 class="modal-title" id="modalLabel"></h5>
        <button #closeModalButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="card-body">
                <h2 style="color: var(--brand-theme-lighter)">BJX<span style="color: var(--brand-theme)">Securities&nbsp;Markets</span></h2>
                <br>
                <h2 class="fw-bold">Forgot Password</h2>
        
                <!-- Forgot Password : Email -->
                <form [formGroup]="forgotPasswordForm" class="mb-2">
                    <div class="col-auto">
                        <div class="input-group mb-2">
                            <div class="input-group-text"><span class="bi bi-person"></span></div>
                            <input formControlName="email" type="email" class="form-control" placeholder="Email">
                        </div>
                        <small *ngIf="forgotPasswordForm.get('email')!.invalid && forgotPasswordForm.get('email')!.dirty" class="form-text error-message">
                            Email field is required</small>
                    </div>
                </form>
        
                <br>
                <app-button name="Forgot Password" [disabled]="forgotPasswordForm.invalid" (onClicked)="forgotPassword()"></app-button>
            </div>
        </div>
    </div>
</div>

<!-- Forgot password toast -->
<app-toast title="All Good!" subTitle="Please check your email for the temporary password to reset password" [show]="this.showToast"></app-toast>