import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { BasePage } from '../../base.page';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BasePage implements OnInit {

  hide: boolean = true;
  signInForm!: FormGroup;
  innerWidth: any;
  useMobileView: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private modalService: ModalService,
    private storageService: StorageService,
    private authService: AuthService) {
      super();
  }

  override ngOnInit() {
    this.initializeApp();
    this.signInForm = this.formBuilder.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.checkResolution();
  }

  public initializeApp() {
    this.innerWidth = window.innerWidth;
  }

  public checkResolution() {
    this.useMobileView = this.innerWidth < 991 ? true : false;
  }

  login() {
    this.loading = true;
    const payload: any = {
      username: this.signInForm.get('username')?.value,
      password: this.signInForm.get('password')?.value,
      rememberMe: true
    }
    this.apiService.getAuthUser(payload).subscribe({
      next: (data: any) => {
        this.loading = false;
        if (data.statusCode === 201 && data.message === 'Admin Login Successful.') {
          this.storageService.setLoginEvent(data.data);
          this.authService.expirationCounter(data.data.expiresIn);
          this.router.navigate(['/account']);
        }
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.message, "Error");
      }
    })
  }

  navToForgotPassword() {
    this.modalService.open(ForgotPasswordComponent);
  }
}
