<ngb-toast class="toast" *ngIf="show" [ngClass]="{ 'success' : success, 'fail' : !success }">
    <div class="row align-items-center">
        <div class="col-auto">
            <div class="p-1" [ngClass]="{ 'icon-success' : success, 'icon-fail' : !success }">
                <span *ngIf="success" class="bi bi-check text-white"></span>
                <span *ngIf="!success" class="bi bi-info-lg text-white"></span>
            </div>
        </div>
        <div class="col">
            <div class="row fw-bolder">{{ title }}</div>
            <div class="row"><small class="p-0">{{ subTitle }}</small></div>
        </div>
    </div>
</ngb-toast>