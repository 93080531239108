import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-trading-view',
  templateUrl: './trading-view.component.html',
  styleUrls: ['./trading-view.component.scss']
})
export class TradingViewComponent implements OnInit, AfterViewInit {

  @Input() name: string = '';
  @Input() exchange: string = '';
  @Input() symbol: string = '';
  @ViewChild('tradingview') tradingview?: ElementRef;

  constructor(
    private _renderer2: Renderer2
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.text = `
    {
      "symbols": [
        [
          "${this.name}",
          "${this.exchange}:${this.symbol}|1D"
        ]
      ],
      "chartOnly": false,
      "width": "99%",
      "height": "700",
      "locale": "en",
      "colorTheme": "light",
      "autosize": true,
      "showVolume": true,
      "showMA": false,
      "hideDateRanges": false,
      "hideMarketStatus": false,
      "hideSymbolLogo": false,
      "scalePosition": "right",
      "scaleMode": "Normal",
      "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
      "fontSize": "10",
      "noTimeScale": false,
      "valuesTracking": "1",
      "changeMode": "price-and-percent",
      "chartType": "area"
    }`;
    this.tradingview?.nativeElement.appendChild(script);
  }
}
