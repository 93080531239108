import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() show: boolean = false;
  @Input() success: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }
}
